<template>
    <!--停车费发票-->
    <div>
        <el-form :model="invoiceData" label-width="120px">
          <el-form-item disabled label="发票类型">
            <el-input v-model="invoiceData.invoiceTypeName"></el-input>
          </el-form-item>
            <el-form-item label="发票代码">
                <el-input v-model="invoiceData.invoiceCode"></el-input>
            </el-form-item>
            <el-form-item label="价税合计">
                <el-input-number v-model="invoiceData.sumAmount" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="invoiceData.remark"></el-input>
            </el-form-item>
            <el-form-item label="发票说明">
                <el-input v-model="invoiceData.invoiceExplain"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    name: "parkInvoiceForm",
    props: ["invoiceData"]
}
</script>
<style scoped>
</style>
