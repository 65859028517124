import { render, staticRenderFns } from "./rollInvoiceForm.vue?vue&type=template&id=b1b208e0&scoped=true"
import script from "./rollInvoiceForm.vue?vue&type=script&lang=js"
export * from "./rollInvoiceForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1b208e0",
  null
  
)

export default component.exports