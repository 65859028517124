import { get, post, download, put, del } from './index.js';


/**
 * 报销发票单列表(分页) 
 * @param {*} data
 */
export const searchReimburseInvoicesPage = (data) => {
  return post('/invoice-reimburse/reimbursepage', data).then((res) => res);
};

/**
 * 报销发票单详情
 * @param {*} data
 */
export const getReimburseInvoiceById = (param) => {
  return get('/invoice-reimburse/details/' + param).then((res) => res);
};

/**
 * 报销发票单批量删除
 * @param data List<Long> ids
 * @returns {Promise<unknown>}
 */
 export const deleteInvoiceByIds = (param) => {
  return post('/invoice-reimburse/batch/delete', param).then((res) => res);
};

/**
 * 发票影像
 * @param {*} data
 */
 export const getInvoiceImagesById = (data) => {
  return get('/invoice-reimburse/imagelist/' + data).then((res) => res);
};

/**
 * 发票列表(分页) 
 * @param {*} data
 */
 export const searchInvoicesList = (data) => {
  return post('/invoice-reimburse/invoicelist', data).then((res) => res);
};





/**
 * 根据报销码查询发票报销单详情
 * @param {*} data
 */
 export const getInvoiceDetailsByBatchNo = (param, batchUid) => {
  return get('/invoice-reimburse/external/detailsByBatchNo/' + param + '/' + batchUid).then((res) => res);
};

/**
 * 发票列表(分页) 
 * @param {*} data
 */
 export const searchInvoicesListByBatchNo = (data) => {
  return post('/invoice-reimburse/external/invoicelist', data).then((res) => res);
};

/**
 * 发票影像
 * @param {*} data
 */
 export const getInvoiceImagesByBatchUid = (param, reimburseId, id) => {
  return get('/invoice-reimburse/external/imagelist/' + param + '/' + reimburseId+ '/' + id).then((res) => res);
};
