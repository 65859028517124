<template>
  <!--发票预览-->
  <div class="invoicePreview">
    <div class="left-box" title="点击图片查看影像" @click="openPreview(imgUrl)">
      <el-image
        style="width: 100%; height: 100%"
        v-if="fileShowType == 'img'"
        :src="imgUrl"
        fit="contain"
        :index="index"
      ></el-image>
    </div>
    <div class="form-box">
      <!--
      数电纸质发票（增值税专用发票）
      数电纸质发票（普通发票）
      数电纸质发票（机动车销售统一发票）
      数电发票（增值税专用发票）
      数电发票（普通发票）
      数电发票（机动车销售统一发票）
      数电发票（航空运输电子客票行程单）
      数电发票（铁路电子客票）

      数电纸质发票（二手车销售统一发票）
      -->
      <FullEleInvoiceForm
        v-if="checkInvoiceType([
          'FullElePaperSpecInvoice',
          'FullElePaperGenerInvoice',
          'FullEleSpecInvoice',
          'FullEleVehPaperInvoice',
          'FullEleGenerInvoice',
          'FullEleVehInvoice',
          'FullElePlaneInvoice',
          'FullEleTrainInvoice',
          'FullEleTollInvoice',

          'FullElePaPerUsedCarInvoice',
          ])"
        :invoiceData="invoiceData"/>
      <!--
      增值税专用发票
      增值税普通发票
      增值税电子专用发票
      增值税电子普通发票
      道路通行费电子普通发票
      机动车销售统一发票
      海关税增值税发票
      货物运输业增值税专用发票
      二手车销售统一发票
      二手车销售电子统一发票
      增值税发票销货清单
      -->
      <VATInvoiceForm
        v-if="checkInvoiceType([
          'SpecInvoice',
          'GenerInvoice',
          'EleSpecInvoice',
          'EleInvoice',
          'EleTollInvoice',
          'VehInvoice',
          'CustomsVATInvoice',
          'TransportInvoice',
          'UsedCarInvoice',
          'EleUsedCarInvoice',
          'VatInvoiceList',
          ])"
        :invoiceData="invoiceData"/>
      <!--增值税普通发票（卷票)-->
      <rollInvoiceForm
        v-if="checkInvoiceType(['VolInvoice'])"
        :invoiceData="invoiceData"/>
      <!--火车票、火车票(无旅客) -->
      <trainInvoiceForm
        v-if="checkInvoiceType(['TrainInvoice'])"
        :invoiceData="invoiceData"/>
      <!--飞机行程单-->
      <planeInvoiceForm
        v-if="checkInvoiceType(['ElePlaneInvoice'])"
        :invoiceData="invoiceData"/>
      <!--出租车票、 网约车行程单-->
      <taxiInvoiceForm
        v-if="checkInvoiceType(['TaxiInvoice','OnlineCarInv'])"
        :invoiceData="invoiceData"/>
      <!--客运发票 || 客运船票 -->
      <passengerInvoiceForm
        v-if="checkInvoiceType(['PassengerInvoice'])"
        :invoiceData="invoiceData"/>
      <!--通用定额发票 || 通用机打发票-->
      <quotaInvoiceForm
        v-if="checkInvoiceType(['QuotaInvoice','GeneralMachineInvoice'])"
        :invoiceData="invoiceData"/>
      <!--停车费发票 -->
      <parkInvoiceForm
        v-if="checkInvoiceType(['parking_invoice'])"
        :invoiceData="invoiceData"/>
      <!--通行费 || 财政票据 || 医疗费收据-->
      <tollInvoiceForm
        v-if="checkInvoiceType(['EleTollInvoice','FullEleTollInvoice','TollInvoice','GovUnTaxIncome','OnlineMedicalInv'])"
        :invoiceData="invoiceData"/>
      <el-form :model="invoiceData" label-width="120px">
        <el-form-item label="可抵扣增值税额">
          <el-input-number
            v-model="invoiceData.deductionTaxMoney"
            style="width:200px"
            :precision="2"
            :min="0"
            :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="综合服务平台查验状态">
          <el-input v-model="invoiceData.verifyStatusName" ></el-input>
        </el-form-item>
      </el-form>
    </div>
    
    <!-- 票据影像弹窗 -->
    <el-image-viewer
      v-if="invoiceImageViewer"
      :url-list="invoiceImages"
      :on-close="handleImageViewerClose"
      style="z-index: 3030"
    />

  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import FullEleInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/FullEleInvoiceForm";
import VATInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/VATInvoiceForm";
import rollInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/rollInvoiceForm";
import trainInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/trainInvoiceForm";
import planeInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/planeInvoiceForm";
import taxiInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/taxiInvoiceForm";
import passengerInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/passengerInvoiceForm";
import quotaInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/quotaInvoiceForm";
import parkInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/parkInvoiceForm";
import tollInvoiceForm from "@/views/invoice-center/component/ticketDialog/ticketsForm/tollInvoiceForm";
import DetailTop from "@/views/invoice-center/component/ticketDialog/DetailTop";

export default {
  props: [
    'invoiceType',//根据发票类型，显示不同的表单
    'invoiceData',
    'index',
    "imgUrl"    //查看图片发票时，获取照片的地址 base 64
  ],
  components: {
    FullEleInvoiceForm,
    ElImageViewer,
    VATInvoiceForm,
    rollInvoiceForm,
    trainInvoiceForm,
    planeInvoiceForm,
    taxiInvoiceForm,
    passengerInvoiceForm,
    quotaInvoiceForm,
    parkInvoiceForm,
    tollInvoiceForm,
    DetailTop
  },
  data() {
    return {
      fileShowType: 'img',
      imgShow: true,
      imgList: [],
      invoiceImageViewer: false,
      invoiceImages: [],
    }
  },
  mounted() {
    this.previewFile()
  },
  methods: {
    // 发票类型核对
    checkInvoiceType(typeList = []) {
      return typeList.indexOf(this.invoiceData.invoiceType) > -1
    },
    previewFile() {
      let imgPath = this.invoiceData.imageUrlPreview ? this.invoiceData.imageUrlPreview : this.invoiceData.imageUrl
      if (imgPath) {
        //给查看预览添加文件路径
        this.imgList.push({
          fileUrl: imgPath
        });
        let s = imgPath.split('.')
        let n = s[s.length - 1].split('?')

        this.fileShowType = 'img'
        this.imgUrl = this.imgUrl ? this.imgUrl : imgPath;
      }
    },
    openPreview(fileUrl) {
      let arr = [];
      arr.push(fileUrl);
      this.invoiceImages = arr;
      this.invoiceImageViewer = true;
    },
    /* 发票影像弹窗关闭 */
    handleImageViewerClose() {
      this.invoiceImageViewer = false;
      this.invoiceImages = [];
    },

  }
}
</script>

<style scoped lang="scss">
.invoicePreview {
  width: 100%;
  height: 100%;
  display: flex;

  .left-box {
    width: calc(100% - 400px);
    height: calc(100% - 1px);
    position: relative;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: help;
    cursor:url("/static/images/amp.png"),pointer;

    .img-box {
      width: 100%;
      height: 100%;
    }

    .pdf-box {
      flex: 1;
      width: 100%;
      height: 100%;
    }
    .ofd-box {
      width: 100%;
      height: 100%;
    }

    .seal-box {
      position: absolute;
      right: -20px;
      bottom: -15px;
    }
  }

  .form-box {
    height: calc(100% - 40px);
    flex: 1;
    margin-top: 1px;
    padding: 20px;
    overflow-y: auto;
  }
  
}
::v-deep .el-input-number .el-input__inner {
  text-align: right;
}

</style>

