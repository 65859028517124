<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-tabs v-model="active" @tab-click="handleTabChange">
          <el-tab-pane label="全部" name="all">
            <template #label>
              <div style="font-weight: bold;">全部</div>
            </template>
          </el-tab-pane>
          <el-tab-pane label="增值税票" name="vat">
          </el-tab-pane>
          <el-tab-pane label="其他发票" name="other">
          </el-tab-pane>
          <el-tab-pane label="报销发票" name="reimburse">
            <template #label>
              <div style="font-weight: bold;">报销发票</div>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content-main">
      <all-invoice-table v-if="active == 'all'" :vatTableData="tableData" :status="tableStatus" :search="this.search" @refresh="handleGetIncomeInvoicePoolTable" />
      <vat-invoice-table v-else-if="active == 'vat'" :vatTableData="tableData" :status="tableStatus" :search="this.search" @refresh="handleGetIncomeInvoicePoolTable" />
      <other-invoice-table v-else-if="active == 'other'" :otherTableData="tableData" :status="tableStatus" :search="this.search" @refresh="handleGetIncomeInvoicePoolTable" />
      <reimburse-invoice-table v-else :otherTableData="tableData" :status="tableStatus" :search="this.search" @refresh="handleGetIncomeInvoicePoolTable" />
    </div>
  </div>
</template>

<script>
import { getIncomeInvoicePoolData } from '@/service/invoice-pool';
import AllInvoiceTable from '@/views/invoice-center/component/my-income/AllInvoiceTable';
import VatInvoiceTable from '@/views/invoice-center/component/my-income/VatInvoiceTable';
import OtherInvoiceTable from '@/views/invoice-center/component/my-income/OtherInvoiceTable';
import ReimburseInvoiceTable from '@/views/invoice-center/component/my-income/ReimburseInvoiceTable';
import { pagingMethods } from '@/util/mixins';

export default {
  name: 'MyIncomeInvoicePool',
  mixins: [pagingMethods],
  components: {
    AllInvoiceTable,
    VatInvoiceTable,
    OtherInvoiceTable,
    ReimburseInvoiceTable
  },
  data() {
    return {
      active: 'all',
      organization: {},
      tableData: [],
      tableStatus: {
        tableLoading: false
      },
      total: 0,
      /* 搜索信息 */
      search: {
        size: 15,
        page: 1,
        isIncInvoice: true,
        isVatInvoice: true
      }
    };
  },
  created() {
    const { voucherId = '', type = '' } = this.$route.query;
    if (voucherId && type) {
      this.search.voucherId = voucherId;
      this.search.isVatInvoice = type == 'other' ? false : true;
      this.active = type;
    }
    this.handleGetIncomeInvoicePoolTable();
  },
  methods: {
    refreshList() {
      this.handleGetIncomeInvoicePoolTable();
    },
    resetForm() {
      this.search = {
        size: 15,
        page: 1,
        isIncInvoice: true,
        isVatInvoice: true
      };
    },
    /* 切换tab */
    handleTabChange() {
      if (this.active == 'other') {
        this.search.isVatInvoice = false;
      } else {
        this.search.isVatInvoice = true;
      }
      this.handleGetIncomeInvoicePoolTable();
    },
    handleSizeChange(value) {
      this.search.size = value;
      this.handleGetIncomeInvoicePoolTable();
    },
    handleCurrentChange(value) {
      this.search.page = value;
      this.handleGetIncomeInvoicePoolTable();
    },
    // /* 进项发票获取表格数据 */
    handleGetIncomeInvoicePoolTable() {
      this.tableStatus.tableLoading = true;
      // 查询请求
      getIncomeInvoicePoolData(this.search).then((res) => {
        if (res.success) {
          this.tableStatus.tableLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.content-select {
  .select-header {
    width: 280px;
    position: relative;
    top: 10px;
    left: calc(100% - 320px);
    z-index: 1;

    .header-right {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;

      ::v-deep .el-input__inner {
        border: none;
        border-radius: 0;
      }
    }

    .header-left span:before {
      content: '|';
      display: inline-block;
      margin-right: 10px;
      background-color: #333;
    }
  }

  .select-content {
    display: inline;
    padding: 0;

    .el-tabs {
      // margin-top: -32px;
    }
  }
}

.content-main {
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

::v-deep .el-tabs__item {
  margin-top: 7px;
  color: #333333;
  font-weight: 400;
  font-size: 16px;
}

::v-deep .el-tabs__item.is-active {
  color: #3d94ff;
}
</style>
