<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>其他发票</span>
        </div>
        <div class="select-items">
        </div>
      </div>
      <div class="select-content">
        <el-form ref="selectFrom" :model="search" inline label-width="160px">
          <el-form-item label="发票类型">
            <el-select v-model="search.invoiceType" placeholder="请选择" clearable>
              <el-option v-for="item in invoiceTypeAllSet" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开票日期">
            <el-date-picker class="date-picker" v-model="search.invoiceTime" type="date" value-format="yyyy-MM-dd" :picker-options="dateOption" placeholder="选择日期" clearable> </el-date-picker>
          </el-form-item>
          <el-form-item label="发票代码">
            <el-input v-model.trim="search.invoiceCode" placeholder="请输入发票代码" maxlength="12" @keyup.enter.native="handleGetInvoiceData()" clearable />
          </el-form-item>
          <span v-show="selectExpended">
            <el-form-item label="发票号码">
              <el-input v-model.trim="search.invoiceNo" placeholder="请输入发票号码" maxlength="8" @keyup.enter.native="handleGetInvoiceData()" clearable />
            </el-form-item>
            <el-form-item label="收票组织">
              <el-input v-model.trim="search.orgName" placeholder="请输入收票组织" maxlength="8" @keyup.enter.native="handleGetInvoiceData()" clearable />
            </el-form-item>
            <el-form-item label="收票日期">
              <el-date-picker class="date-picker" v-model="search.receiveTime" type="date" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="dateOption" placeholder="选择日期" clearable> </el-date-picker>
            </el-form-item>
            <el-form-item label="收票人">
              <el-input v-model.trim="search.creatorName" placeholder="请输入收票人" maxlength="8" @keyup.enter.native="handleGetInvoiceData()" clearable />
            </el-form-item>
            <el-form-item label="乘车人">
              <el-input v-model.trim="search.rider" placeholder="请输入乘车人" maxlength="8" @keyup.enter.native="handleGetInvoiceData()" clearable />
            </el-form-item>
            <el-form-item label="电子客票号码/21位码" class="train21No">
              <el-input v-model.trim="search.train21No" placeholder="请输入电子客票号码/21位码" @keyup.enter.native="handleGetInvoiceData()" style="width: 200px" clearable />
            </el-form-item>
            <el-form-item label="数电发票号码">
              <el-input v-model.trim="search.eInvoiceNo" placeholder="请输入数电发票号码" maxlength="20" @keyup.enter.native="handleGetInvoiceData()" clearable />
            </el-form-item>
          </span>

          <el-form-item>
            <select-button show-status @list-close="handleListClose" @list-show="handleListShow" @select="handleQuery" @reset="handleReset"> </select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <div class="top-left">
            <span>票据列表</span>
            <el-popover placement="right" width="80" trigger="click">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选 </el-checkbox>
              <div class="part"></div>
              <el-checkbox v-for="(pv, pk) in columns" :label="pv.label" :key="pk" v-model="pv.visible" style="display: block; margin-top: 6px" @change="handleCheckedCitiesChange(pk, pv)" />
              <div slot="reference" class="rowSet">
                <img class="imgSet" src="@/assets/icon/set.png" />
                <span>列设置</span>
              </div>
            </el-popover>
          </div>
          <div>
          </div>
        </div>
        <div class="content-table">
          <el-table ref="invoiceCenterTable" :data="tableData" stripe border show-summary :summary-method="getSummaries" v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelection" style="width: 100%">
            <el-table-column type="selection" width="50" fixed="left" />
            <el-table-column type="index" label="序号" width="70" />
            <el-table-column v-if="columns['invoiceType'].visible" prop="invoiceType" :label="columns['invoiceType'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['rider'].visible" prop="rider" :label="columns['rider'].label" width="100" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['invoiceCode'].visible" prop="invoiceCode" :label="columns['invoiceCode'].label" width="120" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['invoiceNo'].visible" prop="invoiceNo" :label="columns['invoiceNo'].label" width="120" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['eInvoiceNo'].visible" prop="eInvoiceNo" :label="columns['eInvoiceNo'].label" width="200" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['train21No'].visible" prop="train21No" :label="columns['train21No'].label" width="200" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['ticketPrice'].visible" prop="amount" :label="columns['ticketPrice'].label" width="100" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['surcharge'].visible" prop="surcharge" :label="columns['surcharge'].label" width="100" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['taxAmount'].visible" prop="taxAmount" :label="columns['taxAmount'].label" width="180" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['amount'].visible" prop="amount" :label="columns['amount'].label" width="100" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['taxRate'].visible" prop="taxRate" :label="columns['taxRate'].label" width="80" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['invoiceTime'].visible" prop="invoiceTime" :label="columns['invoiceTime'].label" width="130" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['receiveTime'].visible" prop="receiveTime" :label="columns['receiveTime'].label" width="180" />
            <el-table-column v-if="columns['batchNo'].visible" prop="batchNo" :label="columns['batchNo'].label" width="130" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['imageType'].visible" prop="imageType" :label="columns['imageType'].label" width="80" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['orgName'].visible" prop="orgName" :label="columns['orgName'].label" width="200" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['creatorName'].visible" prop="creatorName" :label="columns['creatorName'].label" width="120" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['projectName'].visible" prop="projectName" :label="columns['projectName'].label" width="120" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['voucherNo'].visible" prop="voucherNo" :label="columns['voucherNo'].label" width="120" />
            <el-table-column v-if="columns['reimburseDismissal'].visible" prop="reimburseDismissal" :label="columns['reimburseDismissal'].label" width="180" :show-overflow-tooltip="true"/>
            <el-table-column v-if="columns['updated'].visible" prop="updated" :label="columns['updated'].label" width="160" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['operate'].visible" prop="operate" :label="columns['operate'].label" width="120" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="handleViewDetails(scope.row)"> 详情 </el-button>
                 <el-button
                  type="text"
                  :loading="scope.row.loading"
                  :disabled="
                    (scope.row.invoiceFileId == 0 ||
                      scope.row.invoiceFileId == null) &&
                    (scope.row.invoiceDeductionFileId == 0 ||
                      scope.row.invoiceDeductionFileId == null)
                  "
                  @click="handleInvoiceImage(scope.row)"
                  >影像</el-button 
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[15, 30, 50, 100]" :page-size="search.size" layout="total, sizes, prev, pager, next" :total="total"> </el-pagination>
      </div>
    </div>

    <!-- 票据详情弹窗 -->
    <other-invoice-detail :invoiceUpdate="invoiceUpdate" :show.sync="updateVisible" @cancel="handleOtherImageDetailCancel" @save="handleOtherImageDetailSave"></other-invoice-detail>

    <!-- 票据影像弹窗 -->
    <el-image-viewer v-if="invoiceImageViewer" :url-list="invoiceImages" :on-close="handleImageViewerClose" style="z-index: 2020" />

  </div>
</template>

<script>
import { getOtherInvoiceCenterByPage, getInvoiceImageListById } from '@/service/invoice-center';
import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins';
import OtherInvoiceDetail from '@/components/invoice/OtherInvoiceDetail';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { MANUAL_INVOICE_TYPES, OTHER_INVOICE_TYPES } from '@/content/invoice';

export default {
  name: 'OtherInvoice',
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  components: { ElImageViewer, OtherInvoiceDetail },
  data() {
    return {
      exportQuantity: 0,
      exportConfirmation: false,
      invoiceId: '',
      columns: {
        invoiceType: {
          label: '发票类型',
          visible: true
        },
        rider: {
          label: '乘车人',
          visible: true
        },
        invoiceCode: {
          label: '发票代码',
          visible: true
        },
        invoiceNo: {
          label: '发票号码',
          visible: true
        },
        eInvoiceNo: {
          label: '数电发票号码',
          visible: true
        },
        train21No: {
          label: '电子客票号码/21位码',
          visible: true
        },
        ticketPrice: {
          label: '票价',
          visible: true
        },
        surcharge: {
          label: "燃油附加",
          visible: true,
        },
        taxAmount: {
          label: '税额',
          visible: true
        },
        amount: {
          label: '不含税金额',
          visible: true
        },
        taxRate: {
          label: '税率',
          visible: true
        },
        invoiceTime: {
          label: '开票日期',
          visible: true
        },
        receiveTime: {
          label: '收票时间',
          visible: true
        },
        batchNo: {
          label: '识别批次',
          visible: true
        },
        imageType: {
          label: '影像类型',
          visible: true
        },
        creatorName: {
          label: '上传者',
          visible: true
        },
        orgName: {
          label: '上传组织',
          visible: true
        },
        projectName: {
          label: "项目名称",
          visible: true,
        },
        voucherNo: {
          label: "凭证编号",
          visible: true,
        },
        reimburseDismissal: {
          label: "驳回说明",
          visible: true,
        },
        updated: {
          label: "更新时间",
          visible: true,
        },
        operate: {
          label: '操作',
          visible: true
        }
      },
      isIndeterminate: true,
      checkAll: false,
      deductionStatusArr: [],
      buyerInfoAuditArr: [],
      auditStatusArr: [],
      isCollect: true,
      search: {
        invoiceLabel: 'ordinary',
        invoiceCode: '',
        invoiceNo: '',
        invoiceTime: '',
        receiveTime: '',
        invoiceType: '',
        page: 1,
        size: 15,
        isVatInvoice: false,
        train21No: '',
        rider: '',
        eInvoiceNo: '',
        classify: "GR",
      },
      total: 0,
      tableData: [],
      invoiceStatus: [],
      manualVisible: false,
      phoneVisible: false,
      tableLoading: false,
      invoiceTypeAllSet: [],
      manualInvoiceTypes: MANUAL_INVOICE_TYPES,
      sealStatus: [],
      qrCodeImg: '',
      /* 票据详情数据区 */
      drawer: false,
      invoice: {},
      /* 票据影像数据区 */
      invoiceImageViewer: false,
      invoiceImages: [],
      /* 票据清单删除数据区 */
      invoiceListDelete: {},
      /* 票据修改数据区 */
      updateVisible: false,
      invoiceUpdate: {
        id: '',
        invoiceType: '',
        invoiceCode: '',
        invoiceNo: '',
        amount: '',
        checkCode: '',
        verifyCode: '',
        invoiceTime: '',
        buyerTaxNo: '',
        invoiceLabel: 'ordinary',
        rider: '',
        train21No: '',
        action: ''
      },
      /* 编辑发票说明 */
      editVisibleExplain: false,
      invoiceExplain: '',
      invoiceExplainUpdateIds: [],
      /* 批量变更结算状态区 */
      isBatch: false,
      // settlementStatus: [],
      /* 票据删除数据区 */
      auditBatchIds: [],
      auditWindow: false,
      selections: [],
      deleteSelectionsClass: 'batch-deleted',
      selectExpended: false,
      downLoading: false,
      exportLoading: false,
      innerVisible: false,
      invoiceListData: [],
      invoiceListPageSize: 0,
      invoiceListUnmatched: [],
      invoiceListUpdateData: {}
    };
  },
  watch: {
    '$route.path': {
      handler() {
        if (this.$route.path == '/collect/list') {
          this.isCollect = true;
        } else {
          this.isCollect = false;
        }
        this.handleQuery();
      },
      immediate: true
    },
    '$store.state.isCollapse': {
      handler() {
        this.deleteSelectionsClass = this.$store.state.isCollapse ? 'batch-deleted-hidden' : 'batch-deleted';
      },
      immediate: true
    }
  },
  created() {
    this.handleInitDictionaries();
    this.invoiceTypeAllSet = OTHER_INVOICE_TYPES;
    this.sealStatus = [{ label: '全部', value: '' }, ...this.$store.state.enums.InvoiceSealStatus];
  },
  methods: {
    handleOtherImageDetailSave() {
      this.invoiceUpdate = {};
      this.handleGetInvoiceData();
      this.toast('修改成功', 'success');
    },
    handleOtherImageDetailCancel() {
      this.updateVisible = false;
    },  
    // 表格合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '共:';
          return;
        }
        if (index === 1) {
          sums[index] = this.selections.length + '张';
          return;
        }
        const values = this.selections.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value)) && (column.label === '票价' || column.label === '税额' || column.label === '价税合计')) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return Number((prev + curr).toFixed(2));
            } else {
              return prev;
            }
          }, 0);
          if (column.label === '票价') {
            sums[index] = '票价：' + sums[index];
          } else if (column.label === '税额') {
            sums[index] = '税额：' + sums[index];
          } else if (column.label === '价税合计') {
            sums[index] = '价税合计：' + sums[index];
          }
        } else {
          if (column.label === '票价') {
            sums[index] = '票价：' + 0;
          } else if (column.label === '税额') {
            sums[index] = '税额：' + 0;
          } else if (column.label === '价税合计') {
            sums[index] = '价税合计：' + 0;
          }
        }
      });
      return sums;
    }, 
    //列设置全选
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      let cols = this.columns;
      for (let p in cols) {
        cols[p].visible = val;
      }
      localStorage.setItem('otherInvoiceLineSet', JSON.stringify(this.columns));
    },
    //列设置改变
    handleCheckedCitiesChange(pk, pv) {
      Object.keys(this.columns).some((key) => {
        if (pk == key) {
          this.columns[key].visible = pv.visible;
          return true;
        }
      });
      localStorage.setItem('otherInvoiceLineSet', JSON.stringify(this.columns));
    },
    /* 收票中心数据列表 */
    async handleGetInvoiceData() {
      this.tableLoading = true;
      const { success, data } = await getOtherInvoiceCenterByPage(this.search);
      if (!success) {
        this.toast('发票中心数据获取失败！', 'error');
        this.tableLoading = false;
        return;
      }
      // 查询结果处理
      this.total = data.total;
      this.tableData = data.records.map((item) => {
        this.$set(item, 'loading', false);
        return item;
      });
      this.tableLoading = false;
    },
    /* 表格值格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '发票类型':
          return this.handleValueToLabel('InvoiceType', value);
        case '税额':
        case '票价':
        case '价税合计':
          if (value == null) return '--';
          return '￥' + value;
        case '不含税金额':
          //if (row.invoiceType == 'PlaneInvoice') {
          if (row.invoiceType == 'ElePlaneInvoice') {
            if (value == null) return '￥' + '0';
            return '￥' + ((value + Number(row.surcharge)) / (1 + parseFloat(row.taxRate))).toFixed(2);
          } else if (row.invoiceType == 'PassengerInvoice' || row.invoiceType == 'TrainInvoice') {
            if (value == null) return '￥' + '0';
            return '￥' + (value / (1 + parseFloat(row.taxRate))).toFixed(2);
          } else {
            return '--';
          }
        case '影像类型':
          if (row.invoiceFileId == 0 || row.invoiceFileId == null) return '--';
          return this.handleValueToLabel('InvoiceImageType', value);
        case '乘车人':
        case '发票代码':
        case '发票号码':
        case '识别批次':
        case '电子客票号码/21位码':
        case '上传组织':
        case '上传者':
          if (value) return value;
          return '--';
        case '开票日期':
          if (value != undefined) {
            return this.$moment(value).format('YYYY-MM-DD');
          }
          return '--';
        case '税率':
          let str = '';
          if (value && 'null' != value) {
            str = Number(value * 100);
            str += '%';
            return str;
          } else {
            return '--';
          }
        default:
          return value;
      }
    },
    /* 初始化字典值 */
    handleInitDictionaries() {
      // 发票状态
      this.getDicts('invoice_status').then(
        ({ data }) =>
          (this.invoiceStatus = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      ); 
      // 稽核状态
      this.getDicts('audit_status').then(
        ({ data }) =>
          (this.auditStatusArr = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );

      // 抵扣状态
      this.getDicts('deduction_status').then(
        ({ data }) =>
          (this.deductionStatusArr = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );
      // 购方信息稽核
      this.getDicts('buyer_info_audit').then(
        ({ data }) =>
          (this.buyerInfoAuditArr = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );
    },
    /* 展开筛选列表 */
    handleListShow() {
      this.selectExpended = true;
    },
    /* 收起筛选列表 */
    handleListClose() {
      this.selectExpended = false;
    },
    /* 重置查询 */
    handleReset() {
      this.search = {
        invoiceLabel: 'ordinary',
        invoiceCode: '',
        invoiceNo: '',
        invoiceTime: '',
        receiveTime: '',
        invoiceType: '',
        page: 1,
        size: 15,
        isVatInvoice: false,
        train21No: '',
        rider: '',
        eInvoiceNo: ''
      };
    },
    /* 条件查询 */
    handleQuery() {
      this.handleGetInvoiceData();
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value;
    },
    /* 发票详情 */
    handleViewDetails(invoice) {
      this.updateVisible = true;
      this.invoiceUpdate = {
        id: invoice.id,
        invoiceType: invoice.invoiceType,
        invoiceCode: invoice.invoiceCode,
        invoiceNo: invoice.invoiceNo,
        amount: invoice.amount,
        checkCode: invoice.checkCode,
        verifyCode: invoice.checkCode.substring(invoice.checkCode.length - 6),
        invoiceTime: invoice.invoiceTime,
        rider: invoice.rider,
        train21No: invoice.train21No,
        buyerTaxNo: invoice.buyerTaxNo,
        action: 'details'
      };
    },
    /* 发票影像 */
    handleInvoiceImage(invoice) {
      invoice.loading = true;
      getInvoiceImageListById(invoice.id).then((res) => {
        if (res.success) {
          this.invoiceImageViewer = true;
          this.invoiceImages = res.data;
        }
        invoice.loading = false;
      });
    },
    /* 发票影像弹窗关闭 */
    handleImageViewerClose() {
      this.invoiceImageViewer = false;
      this.invoiceImages = [];
    }, 
    /* 切换分页条数 */
    handleSizeChange(value) {
      this.search.size = value;
      this.handleQuery();
    },
    // 翻页
    handleCurrentChange(value) {
      this.search.page = value;
      this.handleQuery();
    }, 
  }
};
</script>
<style lang="scss" scoped>
@import '@/style/select.scss';

.select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      color: #3d94ff;
      margin-right: 20px;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;

      img {
        width: 20px;
        height: 20px;
        vertical-align: sub;
        margin-right: 4px;
      }
    }

    div:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .select-title span:before {
    content: '|';
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .el-button {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1890ff;
        line-height: 22px;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 2px;
          margin-left: 20px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }

  .content-table {
    .el-button {
      padding: 0;
      margin-right: 10px;
    }
  }
}

.manual-input {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;

    ::v-deep .el-select > .el-input,
    .el-input {
      width: 250px;
    }
  }
}

.phone-scan {
  .phone-scan-text {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-bottom: 12px;
    }
  }

  .phone-scan-img {
    .el-image {
      width: 260px;
      height: 260px;
    }
  }
}

.invoice-update {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}

.el-image-viewer {
  position: absolute;
  z-index: 1000;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  margin-bottom: 24px;

  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }

  .message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}

.dialog-delete {
  display: flex;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-invoice-list {
  .list-statistics {
    text-align: left;

    .el-row {
      margin-bottom: 12px;
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .list-table {
    margin-top: 12px;

    div {
      text-align: left;
      margin-bottom: 12px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .el-button {
      padding: 0;
    }
  }
}

.dialog-settlement {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  text-align: left;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0px 24px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-dialog__footer {
  padding: 0px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

.invoice-deleted {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 200px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.invoice-deleted-hidden {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 64px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.dialog-wrap {
  span {
    color: #333333;
  }

  .deletes-btns {
    padding-top: 24px;
    text-align: right;
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.icon {
  color: #ff0000;
  margin-left: 3px;
}

.button-footer {
  text-align: center;

  .button-left {
    margin-right: 65px;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.train21No {
  margin-right: 31px;
  margin-left: -31px;
  .el-input {
    width: 150px;
  }
}
</style>
<style lang="scss">
.el-form--inline .el-form-item {
  margin: 10px 10px 10px 0;
}
.select-button {
  min-width: 200px;
  margin-left: 30px;
}
</style>
