<!--删了这个文件后发现这个文件有用，我又复制回来了，前作者是zhangning、xueyang、wangdianxin-->
<template>
    <div class="canvasImgBox">
        <canvas :id="canvasId" :class="['canvas']"></canvas>
    </div>
</template>

<script>
export default {
    data() {
        return {
            images: [],
        }
    },
    props: ['canvasId', 'invoiceData', 'imgUrl'],
    mounted() {
      this.$nextTick(()=>{
        this.show(this.invoiceData)
      })
    },
    methods: {
        show(p, watch) {
            if (!this.canvasId) return
            let img = new Image();
            let canvas = document.querySelector('#' + this.canvasId);
            let ctx;
            ctx = canvas.getContext("2d");
            img.src = this.imgUrl
            // 当图片加载完再动手
            img.onload = function () {
                // 画布大小和图片尺寸不一样算好比例
                const cWidth = canvas.width, cHeight = canvas.height;
                const imgWidth = img.naturalWidth, imgHeight = img.naturalHeight; //获取图片的原始宽和高
                canvas.width = imgWidth;
                canvas.height = imgHeight;

                // 以图画底
                ctx.drawImage(img, 0, 0);
                // 假设左上角的point 29,24 这是针对原图的坐标系
                // 假设右下角的point 124,52 这是针对原图的坐标系
                let position = p.position.split(',')
                const startPoint = [position[0], position[1]], endPoint = [position[4], position[5]];
                //43,698,867,698,867,1108,43,1108
                // https://developer.mozilla.org/zh-CN/docs/Web/API/CanvasRenderingContext2D/strokeRect
                ctx.strokeStyle = "red";
                ctx.lineWidth = 3;
                ctx.strokeRect(
                    startPoint[0],
                    startPoint[1],
                    (endPoint[0] - startPoint[0]),
                    (endPoint[1] - startPoint[1])
                );
            }
        }
    },
}
</script>

<style lang="scss">
.canvasImgBox {
    width: 100%;
    height: 100%;
    text-align: center;

    .canvas {
        background: #ccc;
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
    }
}
</style>
