var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoicePreview"},[_c('div',{staticClass:"left-box",attrs:{"title":"点击图片查看影像"},on:{"click":function($event){return _vm.openPreview(_vm.imgUrl)}}},[(_vm.fileShowType == 'img')?_c('el-image',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.imgUrl,"fit":"contain","index":_vm.index}}):_vm._e()],1),_c('div',{staticClass:"form-box"},[(_vm.checkInvoiceType([
        'FullElePaperSpecInvoice',
        'FullElePaperGenerInvoice',
        'FullEleSpecInvoice',
        'FullEleVehPaperInvoice',
        'FullEleGenerInvoice',
        'FullEleVehInvoice',
        'FullElePlaneInvoice',
        'FullEleTrainInvoice',
        'FullEleTollInvoice',

        'FullElePaPerUsedCarInvoice' ]))?_c('FullEleInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),(_vm.checkInvoiceType([
        'SpecInvoice',
        'GenerInvoice',
        'EleSpecInvoice',
        'EleInvoice',
        'EleTollInvoice',
        'VehInvoice',
        'CustomsVATInvoice',
        'TransportInvoice',
        'UsedCarInvoice',
        'EleUsedCarInvoice',
        'VatInvoiceList' ]))?_c('VATInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),(_vm.checkInvoiceType(['VolInvoice']))?_c('rollInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),(_vm.checkInvoiceType(['TrainInvoice']))?_c('trainInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),(_vm.checkInvoiceType(['ElePlaneInvoice']))?_c('planeInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),(_vm.checkInvoiceType(['TaxiInvoice','OnlineCarInv']))?_c('taxiInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),(_vm.checkInvoiceType(['PassengerInvoice']))?_c('passengerInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),(_vm.checkInvoiceType(['QuotaInvoice','GeneralMachineInvoice']))?_c('quotaInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),(_vm.checkInvoiceType(['parking_invoice']))?_c('parkInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),(_vm.checkInvoiceType(['EleTollInvoice','FullEleTollInvoice','TollInvoice','GovUnTaxIncome','OnlineMedicalInv']))?_c('tollInvoiceForm',{attrs:{"invoiceData":_vm.invoiceData}}):_vm._e(),_c('el-form',{attrs:{"model":_vm.invoiceData,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"可抵扣增值税额"}},[_c('el-input-number',{staticStyle:{"width":"200px"},attrs:{"precision":2,"min":0,"controls":false},model:{value:(_vm.invoiceData.deductionTaxMoney),callback:function ($$v) {_vm.$set(_vm.invoiceData, "deductionTaxMoney", $$v)},expression:"invoiceData.deductionTaxMoney"}})],1),_c('el-form-item',{attrs:{"label":"综合服务平台查验状态"}},[_c('el-input',{model:{value:(_vm.invoiceData.verifyStatusName),callback:function ($$v) {_vm.$set(_vm.invoiceData, "verifyStatusName", $$v)},expression:"invoiceData.verifyStatusName"}})],1)],1)],1),(_vm.invoiceImageViewer)?_c('el-image-viewer',{staticStyle:{"z-index":"3030"},attrs:{"url-list":_vm.invoiceImages,"on-close":_vm.handleImageViewerClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }