<template>
    <!--客运发票 || 客运船票 -->
    <div>
        <el-form :model="invoiceData" label-width="120px">
          <el-form-item label="发票类型">
            <el-input disabled v-model="invoiceData.invoiceTypeName"></el-input>
          </el-form-item>
            <el-form-item label="发票代码">
                <el-input v-model="invoiceData.invoiceCode"></el-input>
            </el-form-item>
            <el-form-item label="发票号码">
                <el-input v-model="invoiceData.invoiceNo"></el-input>
            </el-form-item>
            <el-form-item label="电子客票号码">
                <el-input v-model="invoiceData.train21No"></el-input>
            </el-form-item>
            <el-form-item label="金额">
                <el-input-number v-model="invoiceData.sumAmount" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="旅客">
                <el-input v-model="invoiceData.rider"></el-input>
            </el-form-item>
            <el-form-item label="身份证">
                <el-input v-model="invoiceData.idCard"></el-input>
            </el-form-item>
            <el-form-item label="出发地">
                <el-input v-model="invoiceData.departure"></el-input>
            </el-form-item>
            <el-form-item label="目的地">
                <el-input v-model="invoiceData.destination"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="invoiceData.remark"></el-input>
            </el-form-item>
            <el-form-item label="发票说明">
                <el-input v-model="invoiceData.invoiceExplain"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "passengerInvoiceForm",
    props:["invoiceData"]
}
</script>

<style scoped>

</style>
