<template>
  <div>
    <div class="search card-shadow-back" style="padding:10px 15px;border-radius:7px;">
      <el-form @submit.native.prevent :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="名称" prop="name">
          <el-input v-model.trim="formInline.name" placeholder=""
                    @keyup.enter.native="seachBtn"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seachBtn();">查询</el-button>
          <el-button type="primary" @click="reset();">重置</el-button>
        </el-form-item>
      </el-form>
<!--      <el-collapse  >-->
<!--        <el-collapse-item title="查询条件" name="1">-->
<!--          <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">-->
<!--            <el-form-item label="商品或服务名" prop="taxRevenueName">-->
<!--              <el-input v-model.trim="formInline.taxRevenueName" placeholder=""-->
<!--                        @keyup.enter.native="seachBtn"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item>-->
<!--              <el-button type="primary" @click="seachBtn();">查询</el-button>-->
<!--              <el-button type="primary" @click="reset();">重置</el-button>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </el-collapse-item>-->
<!--      </el-collapse>-->
    </div>
    <el-table  style="margin-bottom: 20px" class="max-table table-reset" border :data="tableData" :header-cell-style="{background:'#f0f2f5'}"
              @selection-change="handleSelectionChange">
      <el-table-column v-if="tableData.length>0"  align="center" width="60">
        <template slot-scope="scope">
          <el-radio v-model="radio" @change="handleSelectionChange(scope.row)"  :label="scope.row.id">
            <span class="el-radio__label"></span>
          </el-radio>
        </template>
      </el-table-column>
      
      <el-table-column show-overflow-tooltip label="项目编码" 
        prop="projectCode" min-width="120">
      </el-table-column>
      <el-table-column
        prop="projectName"
        label="项目名称"
        align="center"
        min-width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="nsrsbh"
        label="纳税人识别号"
        align="center"
        min-width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="nsrmc"
        label="纳税人名称"
        align="center"
        min-width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="taxMethod"
        label="计税方式"
        align="center"
        min-width="120"
        :formatter="taxMethodFormat"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="invoiceAmount"
        label="合同合计金额"
        align="center"
        min-width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="relativeName"
        label="合同相对方名称"
        align="center"
        min-width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="isCrossRegion"
        label="是否跨区域"
        align="center"
        min-width="120"
        :formatter="isCrossRegionFormat"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="isPrepay"
        label="是否预交"
        align="center"
        min-width="120"
        :formatter="isPrepayFormat"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="status"
        label="项目状态"
        align="center"
        min-width="120"
        :formatter="statusFormat"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <el-pagination   style="position: relative;text-align: right;" :current-page.sync="page.current" :total="page.total" @current-change="curChange"
                   :page-size.sync="page.size"
                   @size-change="curChange"
                   :page-sizes="[20, 50, 100]"
                   layout="total,sizes,prev,pager,next,jumper"></el-pagination>
<!--    <div slot="footer" class="dialog-footer" style="text-align: right;margin:20px 20px 20px 0;">-->
<!--      <el-button @click="$emit('showCategory')">取 消</el-button>-->
<!--      <el-button type="primary" @click="onConfirm" v-antiSnake >确 定</el-button>-->
<!--    </div>-->
  </div>

</template>

<script>
import {list} from '@/service/project.js';

export default {
  name: "goodsOrServesDialog",
  props:["detailList"],
  data() {
    return {
      radio:"",
      tableData: [],//列表数据
      //搜索框form
      formInline: {
        taxRevenueName: '',
        name: '',

      },
      //分页数据
      page: {
        size: 20,
        current: 1,
        total: 0
      },
      selectedList:[]
    }
  },
  methods:{
    onConfirm(){
      this.$emit('showCategory')
    },
    //
    handleSelectionChange(res,row) {
      if(Array.isArray(res)){
        this.selectedList = res;
      }else{
        this.selectedList[0]=res
      }
      if(row){
        this.selectedList[0]={...row};
        // this.selectedList[0].taxRevenueName='*'+res.taxRevenueName+'*'+row.name
      }
      this.$emit('selectGoods', this.selectedList[0]);
      this.$emit('showCategory');
    },
    curChange() {//点击分页
      this.list(this.page, this.formInline);
    },
    //清空
    reset() {
      this.$refs["formInline"].resetFields();
      this.seachBtn();
    },
    seachBtn() {//搜索方法
      this.page.current = 1;
      this.page.total = 0;
      this.list();
    },
    // 计税方式格式化
    taxMethodFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '计税方式':
          if (value === 1) {
            return '一般计税';
          } else if (value === 2) {
            return '简易征收（老项目）';
          } else if (value === 3) {
            return '简易征收（甲供材）';
          } else if (value === 4) {
            return '简易征收（其他）';
          } else {
            return '';
          }
      }
    },
    // 是否跨区域
    isCrossRegionFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '是否跨区域':
          if (value === 1) {
            return '是';
          } else if (value === 0) {
            return '否';
          } else {
            return '';
          }
      }
    },
    // 是否预交
    isPrepayFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '是否预交':
          if (value === 1) {
            return '是';
          } else if (value === 0) {
            return '否';
          } else {
            return '';
          }
      }
    },
    // 合同状态
    statusFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '合同状态':
          if (value === 1) {
            return '正常';
          } else if (value === 2) {
            return '终止';
          } else {
            return '';
          }
      }
    },
    list(){
      this.page.total = 0;
      this.tableData = [];
      list(this.formInline,this.page).then(res => {
        if(res.success && res.data.records.length > 0) {
          const data = res.data;
          this.page.total = data.total;
          this.tableData = data.records;
        }
      }).catch((err) => {
      });
    },

  },
  mounted(){
    this.list()
  }
}
</script>

<style scoped>
/deep/ .el-radio__label{
  display: none;
}
</style>
