<template>
  <div class="ticketDialogBox">
    <el-dialog
      title="查看"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :before-close="dialogHide"
      append-to-body
      @open="dialogOpen"
      width="70%">
      <el-carousel
        height="500"
        :interval="5000"
        :arrow="type=='invoiceOcr'?'always':'never'"
        :autoplay="false"
        indicator-position="none">
        <el-carousel-item
          v-for="(item,index) in invoiceOcrList"
          :key="index"
          @change="invoiceChange(index)"
        >
          <invoicePreview
            v-if="dialogFormVisible"
            :invoiceType="item.invoiceType"
            :invoiceData="item"
            :index="index"
            :imgUrl="item.imgUrl ? item.imgUrl : imgUrl"
          ></invoicePreview>
        </el-carousel-item>
      </el-carousel>
      <div slot="footer" v-if="showSave">
        <el-button type="danger" v-if="type=='invoiceOcr'" @click="deleteNow()" v-antiSnake>删除</el-button>
        <el-button type="primary" v-if="type!='look'" @click="saveNow()" v-antiSnake>保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import invoicePreview from '@/views/invoice-center/component/ticketDialog/invoicePreview'
import {
  getInvoiceImageListById,
  getInvoiceListsById,
  updateInvoiceById,
} from "@/service/invoice-center";
import { ALL_MANUAL_INVOICE_TYPES } from "@/content/invoice";
import {mapState} from "vuex";

export default {
  name: "ticketDialog",
  components: {
    invoicePreview,
  },
  props: {
    dialogFormVisible: {
      required: false,//是否必须提供字段
      default: false
    },
    //发票详情  invoiceOcr
    invoiceOcrList: {
      type: Array,
      default: () => {
        return []
      }, //默认值
    },
    //判断是新增还是修改  invoiceOcr  edit
    type: {
      type: String,
      default: "", //默认值
    },
    //发票新增传参
    filePath: {
      type: String,
      default: "", //默认值
    },
    //查看图片发票时，获取照片的地址 base 64
    imgUrl: {
      type: String,
      default: "", //默认值
    },
    // 是否显示按钮栏
    showSave:{
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      commonS: ALL_MANUAL_INVOICE_TYPES,// 票夹类型
    }),
  },
  data() {
    return {
      loading: null,
      typeAry: "image/jpg,image/jpeg,image/png,image/gif,image/bmp,image/pdf,image/JPG,image/JPEG,image/PBG,image/GIF,image/BMP,image/PDF,.PDF,.pdf,application/pdf,application/PDF,.ofd,application/ofd,.OFD,application/OFD",
      activeName: 'one',
      index: 0,//索引
      ticketList: [],//选中的发票
      transderData: {
        transderDialog: false,
        tranList: []
      },
      current: 0,
      //搜索栏状态字段
      selectStatus: [
        {name: '有效', id: 0},
        {name: '无效', id: 1}
      ],
      //弹窗录入状态
      modalSelectStatus: [
        {name: '有效', id: 0},
        {name: '无效', id: 1}
      ],
      activeNames: ['0'],//默认展开搜索栏
      jinyong: true,//修改或查看禁用输入框
      //搜索form
      formInline: {
        invoiceType: '',
        isUse: '',
        invoiceCode: '',
        invoiceNumber: '',
        invoiceDate: '',
        totalAmount: '',
        searchStartDate: '',
        searchEndDate: '',
        reimburseCode: '',
        queryTime: "",
      },
      modalTitle: "",//弹窗标题
      tableDataList: [],//列表数据
      //分页数据
      page: {
        size: 20,
        current: 1,
        total: 0
      },
      fileAccept: ' .pdf, .PDF, .jpg, .JPG, .JPEG, .png, .PNG',
      invoiceTypeToCheck: [], // 需要查验的发票类型集合
    }
  },
  mounted() {
    // 需要查验的发票类型集合
    this.invoiceTypeToCheck = ALL_MANUAL_INVOICE_TYPES;
  },
  methods: {
    invoiceChange(index) {
      this.index = index
    },
    dialogHide(onload) {
      this.$emit('dialogInvoiceHide', onload);
      this.imgUrl = ''
    },
    // 根据 id查询 发票详情
    getInvoicePoolById(invoiceId) {
      getInvoiceListsById({invoiceId: invoiceId}).then(res => {
        if (res && res.data.code === 200) {
          let data = res.data.data
          // 将接口返回的查验结果中的指定字段替换原来发票信息的指定字段
          this.invoiceOcrList[this.index].buyerName = data.buyerName // 购买方名称
          this.invoiceOcrList[this.index].buyerTaxNo = data.buyerTaxNo // 购买方税号
          this.invoiceOcrList[this.index].buyerAddressTel = data.buyerAddressTel // 购买方地址电话
          this.invoiceOcrList[this.index].buyerBankAccount = data.buyerBankAccount // 购买方银行账号
          this.invoiceOcrList[this.index].sellerName = data.sellerName // 销售方名称
          this.invoiceOcrList[this.index].sellerTaxNo = data.sellerTaxNo // 销售方税号
          this.invoiceOcrList[this.index].sellerAddressTel = data.sellerAddressTel // 销售方地址电话
          this.invoiceOcrList[this.index].sellerBankAccount = data.sellerBankAccount // 销售方银行账号
          this.invoiceOcrList[this.index].amount = data.amount // 发票金额
          this.invoiceOcrList[this.index].taxAmount = data.taxAmount // 税额
          this.invoiceOcrList[this.index].sumAmount = data.sumAmount // 价税合计
          this.invoiceOcrList[this.index].remark = data.remark // 备注
          this.invoiceOcrList[this.index].deductTaxAmount = data.deductTaxAmount // 可抵扣增值税额
          this.invoiceOcrList[this.index].items = data.itemVOS // 发票分录-查验结果转换后
          this.invoiceOcrList[this.index].source = data.source // 来源0OCR 1查验
        }
      }).catch()
    },
    //保存扫描的照片
    saveNow() {
       if (this.type == 'edit') { //修改
        this.editSave();
      }
    },
    //删除扫描的照片
    deleteNow() {
      this.invoiceOcrList.splice(this.index, 1);
      if (this.invoiceOcrList.length == 0) this.$emit('dialogInvoiceHide');
    },
    // 对图片进行画框处理
    async getFilePositionCanvasImg(position, imageUrl) {
      if (imageUrl && position) {
        let path = imageUrl.substr(imageUrl.length - 5, 5)
        let isImg = false
        let fs = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG']
        fs.forEach(v => {
          if (path.indexOf(v) != -1) {
            isImg = true
          }
        })
        if (!isImg) {
          return false
        }
        let coordinate = []
        position.split(",").forEach(v => {
          coordinate.push(parseFloat(v))
        })
        let data = {
          imageUrl: imageUrl,
          coordinate: coordinate,
          type: 'invoice'
        }
        let res = await getInvoiceImageListById(data)
        if (res.data.code == 200) {
          return res.data.data
        } else {
          return false
        }
      } else {
        return false
      }
    },
    async dialogOpen() {
      if (this.type == 'edit') {
        for (var i = 0; i < this.invoiceOcrList.length; i++) {
          let item = this.invoiceOcrList[i]
          if(item.imageUrl.indexOf('.jpg') != -1 ||
            item.imageUrl.indexOf('.jpeg') != -1 ||
            item.imageUrl.indexOf('.pgb') != -1 ||
            item.imageUrl.indexOf('.png') != -1 ||
            item.imageUrl.indexOf('.gif') != -1
          ) {
            item.imageUrl = this.filePath  // 源文件地址
            item.imageUrlPreview = item.imageUrl // 预览图片 添加发票边框
            item.imgUrl = item.imageUrl;
            item.invoiceTypeName = this.handleValueToLabel("InvoiceType", item.invoiceType);
            item.verifyStatusName = this.handleValueToLabel("InvoiceVerifyStatus", item.verifyStatus);
          }
          this.$set(this.invoiceOcrList, i, item)
        }
      }
    },
    //修改保存
    editSave() {
      updateInvoiceById(this.invoiceOcrList[0]).then((res) => {
        this.dialogHide('onload');
      }).catch(() => {
        this.dialogHide('onload');
      })
    },
  }
}
</script>

<style scoped lang="scss">

::v-deep .el-carousel {
  height: 100%;
}

::v-deep .el-carousel__container {
  height: 100% !important;
}

::v-deep .el-dialog {
  margin: 15vh auto 0;
}

::v-deep .el-dialog__body {
  padding: 0;
  height: calc((70vw - 400px) / 1.5); //为了保证电子发票正常显示，弹窗高度=(75vw弹窗宽度-400px右侧表单宽度)/1.5长宽比例
}

::v-deep .el-dialog__footer {
  padding: 0;
  background-color: #eeeeee;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
