<template>
    <!--飞机行程单-->
    <div>
        <el-form :model="invoiceData" label-width="120px">
          <el-form-item label="发票类型">
            <el-input disabled v-model="invoiceData.invoiceTypeName"></el-input>
          </el-form-item>
            <el-form-item label="电子客票号码">
                <el-input v-model="invoiceData.train21No"></el-input>
            </el-form-item>
            <el-form-item label="开票日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="invoiceData.invoiceTime" type="date"  :clearable="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="旅客">
                <el-input v-model="invoiceData.rider"></el-input>
            </el-form-item>
            <el-form-item label="身份证">
                <el-input v-model="invoiceData.idCard"></el-input>
            </el-form-item>
            <el-form-item label="合计">
                <el-input-number v-model="invoiceData.sumAmount" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="票价">
                <el-input-number v-model="invoiceData.amount" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="民航发展基金">
                <el-input-number v-model="invoiceData.caacDevelopmentFund" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="燃油附加费">
                <el-input-number v-model="invoiceData.surcharge" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="其他税费">
                <el-input-number v-model="invoiceData.otherTaxes" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="invoiceData.remark"></el-input>
            </el-form-item>
            <el-form-item label="发票说明">
                <el-input v-model="invoiceData.invoiceExplain"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    name: "planeInvoiceForm",
    props: ["invoiceData"]

}
</script>
<style scoped>
</style>
