// 根据发票代码解析发票类型
export const parseCategory = (v) => {
  let invCat = '';
  let fplx = doParseCategory(v);
  console.log(fplx, '根据发票代码解析发票类型');
  switch (fplx) {
    case '04':
      invCat = 'GenerInvoice';
      break;

    case '01':
      invCat = 'SpecInvoice';
      break;
    case '00':
      invCat = 'EleInvoice';
      break;
    case '10':
      invCat = 'EleInvoice';
      break;

    case '20':
      invCat = 'EleSpecInvoice';
      break;

    case '03':
      invCat = 'VehInvoice';
      break;

    case '11':
      invCat = 'VolInvoice';
      break;

    case '14':
      invCat = 'EleInvoice';
      break;

    case '15':
    default:
      invCat = 'GenerInvoice';
      break;
  }

  return invCat;
};

const doParseCategory = (invoiceCode) => {
  let c = 99;
  if (!invoiceCode) return c;
  var len = invoiceCode.length; //发票代码长度
  console.log(len, '发票代码长度');
  const _ORI = '11'; //普通卷式发票

  const _OEI = '10'; //普通电子发票

  const _VSI = '03'; //机车销售专票

  const _OI = '04'; //普通发票

  const _SI = '01'; //专用发票

  const _GI = '02'; //货运专票

  const _OP = '14'; //增值税普通发票（通行费）

  const _UC = '15'; //二手车销售统一发票

  const specialOEICodes = ['144031539110', '131001570151', '133011501118', '111001571071'];
  const specialOICodes = ['111001781111'];

  let b = '';
  // 12位发票代码
  if (len == 12) {
    b = invoiceCode.substring(7, 1);

    specialOEICodes.forEach((item) => {
      if (invoiceCode == item) {
        return _OEI;
      }
    });

    specialOICodes.forEach((item) => {
      if (invoiceCode == item) {
        return _OI;
      }
    });

    let sjdm = invoiceCode.substring(0, 1); //税局代码 1.国税局 2.地税局 0.国家税务总局
    let fpzl = invoiceCode.substring(10, 12);

    if (c == 99) {
      //取消对第一个数值（税局代码）的判断，因为发现新版的普通发票的代码首字符可以是非0值。比如（111001781111）

      //if (sjdm == "0")

      // {
      if (fpzl == '11') {
        c = _OEI;
      }

      if (fpzl == '04' || fpzl == '05') {
        c = _OI;
      }

      if (fpzl == '06' || fpzl == '07') {
        c = _ORI;
      }

      if (fpzl == '12') {
        c = _OP;
      }

      if (c == '99' && fpzl == '17') {
        c = _UC;
      }
      // }
    }

    if (c == '99' && sjdm != '0' && b == '2') {
      c = _VSI;
    }

    if (c == '99') {
      //兼容2018年01月01日之后的增值税普通发票

      c = _OI;
    }
  } // 10 位发票代码
  else if (len == 10) {
    b = invoiceCode.substring(7, 8);
    if (b == '1' || b == '5') {
      c = _SI;
    } else if (b == '6' || b == '3') {
      c = _OI;
    } else if (b == '7' || b == '2') {
      c = _GI;
    }
  }
  return c;
};
