<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>增值税票</span>
        </div>
        <div class="select-items">
        </div>
      </div>
      <div class="select-content">
        <el-form ref="selectFrom" :model="search" label-width="160px" inline>
          <el-form-item label="发票代码">
            <el-input
              v-model.trim="search.invoiceCode"
              placeholder="请输入发票代码"
              maxlength="12"
              @keyup.enter.native="handleGetInvoiceData()"
              clearable
            />
          </el-form-item>
          <el-form-item label="发票号码">
            <el-input
              v-model.trim="search.invoiceNo"
              placeholder="请输入发票号码"
              maxlength="8"
              @keyup.enter.native="handleGetInvoiceData()"
              clearable
            />
          </el-form-item>
          <el-form-item label="收票日期">
            <el-date-picker
              class="date-picker"
              v-model="search.receiptDate"
              :picker-options="dateOption"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
            >
            </el-date-picker>
          </el-form-item>
          <span v-show="selectExpended">
            <el-form-item label="识别批次">
              <el-input
                v-model.trim="search.batchNo"
                placeholder="请输入识别批次"
                maxlength="64"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="开票日期">
              <el-date-picker
                class="date-picker"
                v-model="search.invoiceTime"
                type="date"
                value-format="yyyy-MM-dd"
                :picker-options="dateOption"
                placeholder="选择日期"
                clearable
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="发票说明">
              <el-input
                v-model.trim="search.invoiceExplain"
                placeholder="请输入发票说明"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="发票查验平台查验状态">
              <el-select
                v-model="search.verifyStatus"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in verifyStatusSet"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="购方名称">
              <el-input
                v-model.trim="search.buyerName"
                placeholder="请输入购方名称"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="购方信息稽核">
              <el-select
                v-model="search.buyerInfoAudit"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in buyerInfoAuditArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销方名称">
              <el-input
                v-model.trim="search.sellerName"
                placeholder="请输入销方名称"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="发票类型">
              <el-select
                @change="invoiceTypeSetChange"
                multiple
                collapse-tags
                v-model="search.invoiceTypeList"
                placeholder="请选择"
                clearable
              >
                <el-option
                  :disabled="item.disabled"
                  v-for="item in invoiceTypeAllSet"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否下载">
              <el-select
                v-model="search.ifDownload"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in IfDownloadList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="有无备注">
              <el-select
                v-model="search.ifHasRemark"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in IfRemarkList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model.trim="search.remark"
                placeholder="请输入备注"
                maxlength="50"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="发件人">
              <el-input
                v-model.trim="search.sender"
                placeholder="请输入发件人"
                maxlength="50"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="收票类型">
              <el-select
                v-model="search.channel"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in TypeOfReceiptList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数电发票号码">
              <el-input
                maxlength="20"
                v-model.trim="search.eInvoiceNo"
                placeholder="请输入数电发票号码"
                @keyup.enter.native="handleGetInvoiceData()"
                clearable
              />
            </el-form-item>
            <el-form-item label="发票状态">
              <el-select
                v-model="search.invoiceStatus"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in invoiceStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="勾选状态">
              <el-select
                v-model="search.isCheck"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="已勾选" value="1"></el-option>
                <el-option label="未勾选" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="抵扣状态">
              <el-select
                v-model="search.deductionStatus"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="未抵扣" value="0"></el-option>
                <el-option label="抵扣" value="1"></el-option>
                <el-option label="退税" value="2"></el-option>
                <el-option label="不抵扣" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票来源">
              <el-select
                v-model="search.receiveFrom"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in receiveFromList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="匹配状态">
              <el-select
                v-model="search.matchStatus"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="未匹配" value="0"></el-option>
                <el-option label="已匹配" value="1"></el-option>
                <el-option label="匹配失败" value="2"></el-option>
              </el-select>
            </el-form-item>
          </span>
          <el-form-item>
            <select-button
              show-status
              @list-close="handleListClose"
              @list-show="handleListShow"
              @select="handleQuery"
              @reset="handleReset"
            >
            </select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <div class="top-left">
            <span>票据列表</span>
            <el-popover placement="right" width="80" trigger="click">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选
              </el-checkbox>
              <div class="part"></div>
              <el-checkbox
                v-for="(pv, pk) in columns"
                :label="pv.label"
                :key="pk"
                v-model="pv.visible"
                @change="handleCheckedCitiesChange(pk, pv)"
              />
              <div slot="reference" class="rowSet">
                <img class="imgSet" src="@/assets/icon/set.png" />
                <span>列设置</span>
              </div>
            </el-popover>
          </div>
          <div>
          </div>
        </div>
        <div class="content-table">
          <el-table
            ref="invoiceCenterTable"
            :data="tableData"
            stripe
            border
            show-summary
            :summary-method="getSummaries"
            v-loading="tableLoading"
            :header-cell-style="handleHeaderCellStyle"
            @selection-change="handleSelection"
            style="width: 100%"
          >
            <el-table-column type="selection" width="50" fixed="left" />
            <el-table-column
              type="index"
              label="序号"
              width="50"
              fixed="left"
            />
            <el-table-column
              v-if="columns['invoiceType'].visible"
              prop="invoiceType"
              :label="columns['invoiceType'].label"
              width="150"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['invoiceCode'].visible"
              prop="invoiceCode"
              :label="columns['invoiceCode'].label"
              width="120"
            />
            <el-table-column
              v-if="columns['invoiceNo'].visible"
              prop="invoiceNo"
              :label="columns['invoiceNo'].label"
              width="100"
            />
            <el-table-column
              v-if="columns['eInvoiceNo'].visible"
              prop="eInvoiceNo"
              :label="columns['eInvoiceNo'].label"
              min-width="200"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['sumAmount'].visible"
              prop="sumAmount"
              :label="columns['sumAmount'].label"
              width="200"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['amount'].visible"
              prop="amount"
              :label="columns['amount'].label"
              width="150"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['taxAmount'].visible"
              prop="taxAmount"
              :label="columns['taxAmount'].label"
              width="150"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['buyerName'].visible"
              prop="buyerName"
              :label="columns['buyerName'].label"
              min-width="200"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['buyerInfoAudit'].visible"
              prop="buyerInfoAudit"
              :label="columns['buyerInfoAudit'].label"
              width="120"
              :formatter="handleTableValueFormat"
            >
              <template slot="header" slot-scope="scope">
                <div>
                  <span>购方信息稽核</span>
                  <el-tooltip placement="top">
                    <div slot="content" v-html="tips"></div>
                    <i class="el-icon-question icon"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['sellerName'].visible"
              prop="sellerName"
              :label="columns['sellerName'].label"
              min-width="200"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['invoiceTime'].visible"
              prop="invoiceTime"
              :label="columns['invoiceTime'].label"
              width="150"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['receiveTime'].visible"
              prop="receiveTime"
              :label="columns['receiveTime'].label"
              width="180"
            />
            <el-table-column
              v-if="columns['verifyStatus'].visible"
              prop="verifyStatus"
              :label="columns['verifyStatus'].label"
              width="180"
            >
              <template slot-scope="scope">
                <div
                  :style="{
                    color: handleVerifyAndSealStatusColor(
                      scope.row.verifyStatus
                    ),
                  }"
                >
                  <span v-if="scope.row.verifyStatus != 'FAILURE'">
                    {{
                      handleValueToLabel(
                        "InvoiceVerifyStatus",
                        scope.row.verifyStatus
                      )
                    }}
                  </span>
                  <el-tooltip v-else class="item" effect="dark" placement="top">
                    <div slot="content">
                      <span>{{ scope.row.verifyMessage }}</span>
                    </div>
                    <span>{{
                      handleValueToLabel(
                        "InvoiceVerifyStatus",
                        scope.row.verifyStatus
                      )
                    }}</span>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['remark'].visible"
              prop="remark"
              :label="columns['remark'].label"
              width="320"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['invoiceStatus'].visible"
              prop="invoiceStatus"
              :label="columns['invoiceStatus'].label"
              min-width="120"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['auditStatus'].visible"
              prop="auditStatus"
              :label="columns['auditStatus'].label"
              width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['deductionStatus'].visible"
              prop="deductionStatus"
              :label="columns['deductionStatus'].label"
              width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['batchNo'].visible"
              prop="batchNo"
              :label="columns['batchNo'].label"
              width="320"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['imageType'].visible"
              prop="imageType"
              :label="columns['imageType'].label"
              width="80"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['orgName'].visible"
              prop="orgName"
              show-overflow-tooltip
              :label="columns['orgName'].label"
              width="250"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['creatorName'].visible"
              prop="creatorName"
              :label="columns['creatorName'].label"
              min-width="120"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['invoiceExplain'].visible"
              prop="invoiceExplain"
              :label="columns['invoiceExplain'].label"
              min-width="120"
              show-overflow-tooltip
              :formatter="handleTableValueFormat"
            />

            <el-table-column
              v-if="columns['ifDownload'].visible"
              prop="ifDownload"
              :label="columns['ifDownload'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            >
              <template slot-scope="scope">
                <span>
                  {{ scope.row.ifDownload == true ? "是" : "否" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns['sender'].visible"
              prop="sender"
              :label="columns['sender'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['senderEmail'].visible"
              prop="senderEmail"
              :label="columns['senderEmail'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['receiveTimeS'].visible"
              prop="receiveTime"
              :label="columns['receiveTimeS'].label"
              min-width="180"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['channel'].visible"
              prop="channel"
              :label="columns['channel'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />

            <el-table-column
              v-if="columns['owneriod'].visible"
              prop="owneriod"
              :label="columns['owneriod'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['checkedTime'].visible"
              prop="checkedTime"
              :label="columns['checkedTime'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['matchStatus'].visible"
              prop="matchStatus"
              :label="columns['matchStatus'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['receiveFrom'].visible"
              prop="receiveFrom"
              :label="columns['receiveFrom'].label"
              min-width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['projectName'].visible"
              prop="projectName"
              :label="columns['projectName'].label"
              width="120"
              :formatter="handleTableValueFormat"
            />
            <el-table-column
              v-if="columns['voucherNo'].visible"
              prop="voucherNo"
              :label="columns['voucherNo'].label"
              width="120"
            />
            <el-table-column
              v-if="columns['reimburseDismissal'].visible"
              prop="reimburseDismissal"
              :label="columns['reimburseDismissal'].label"
              :show-overflow-tooltip="true"
              width="180"
            />
            <el-table-column
              v-if="columns['updated'].visible"
              prop="updated"
              :label="columns['updated'].label"
              width="160"
              :formatter="handleTableValueFormat"
            />

            <el-table-column
              v-if="columns['operate'].visible"
              prop="operate"
              :label="columns['operate'].label"
              width="120"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleViewDetails(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  :loading="scope.row.loading"
                  :disabled="
                    (scope.row.invoiceFileId == 0 ||
                      scope.row.invoiceFileId == null) &&
                    (scope.row.invoiceDeductionFileId == 0 ||
                      scope.row.invoiceDeductionFileId == null)
                  "
                  @click="handleInvoiceImg(scope.row)"
                  >影像</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[15, 30, 50, 100]"
          :page-size="search.size"
          layout="total, sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 票据详情弹窗 -->
    <invoice-detail
      :loading="loading"
      :show.sync="drawer"
      :invoice="invoice"
    ></invoice-detail>
    <!-- 票据影像弹窗 -->
    <el-image-viewer
      v-if="invoiceImageViewer"
      :url-list="invoiceImages"
      :on-close="handleImageViewerClose"
      style="z-index: 2020"
    />

    <!-- 影像预览 -->
    <el-dialog width="80%" title="发票影像" :visible.sync="invoiceImageVisible">
      <col-invoice-image
        v-if="invoiceImageVisible"
        :col-invoice-id="colInvoiceId"
      ></col-invoice-image>
    </el-dialog>
  </div>
</template>

<script>
import { DataUtil } from "@/util/dataUtil";
import {
  againPushInvoice,
  buyerInfoAudit,
  deleteInvoiceByIds,
  pushInvoiceByIds,
  getVATInvoiceCenterByPage,
  selectInvoice,
  getInvoiceImageListById,
  getInvoiceListsById,
  handleExportBatch,
  invoiceExplainUpdate,
  batchGeneratePdf,
  batchGeneratePdfPreQuery,
  invoiceDownload,
  generatePdf,
} from "@/service/invoice-center";
import {
  changeMethods,
  dateOption,
  pagingMethods,
  tableStyle,
} from "@/util/mixins";
import InvoiceDetail from "@/components/invoice/InvoiceDetail";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { INVOICE_IMAGE_TYPE, MANUAL_INVOICE_TYPES } from "@/content/invoice";
import { getCurrency } from "@/service/pay/currency";
import { listTntConfig } from "@/service/system/config";
import ColInvoiceImage from "@/views/invoice-center/component/ColInvoiceImage.vue";
export default {
  name: "InvoiceCenterList",
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  components: {
    InvoiceDetail,
    ElImageViewer,
    ColInvoiceImage,
  },
  data() {
    return {
      sortOrder: "1",
      screenWidth: 400,
      dlFileParam: {
        invoiceIds: [],
        groupRule: "NG",
        fileTypes: ["PDF"], //批量下载文件类型
      },
      dlFileTypes: INVOICE_IMAGE_TYPE, //文件类型枚举

      value: "",
      form: {
        orgId: "",
        name: "",
        region: "",
      },
      invoiceImageVisible: false,
      downloadInBatchesVisible: false,
      takerOrganizationVisible: false,
      TypeOfReceiptList: [
        {
          value: "PHOTOGRAPH",
          label: "拍照收票",
        },
        {
          value: "SCAN",
          label: "扫码",
        },
        {
          value: "GUN",
          label: "扫码枪",
        },
        {
          value: "SCANNER",
          label: "扫描仪",
        },

        {
          value: "PICTURE",
          label: "图片上传",
        },
        {
          value: "IMG",
          label: "图片",
        },
        {
          value: "PDF",
          label: "PDF文件",
        },
        {
          value: "OFD",
          label: "OFD文件",
        },
        {
          value: "XML",
          label: "XML文件",
        },
        {
          value: "IMPORT",
          label: "导入收票",
        },
        {
          value: "EMAIL",
          label: "邮箱",
        },
        {
          value: "SUPPLIER",
          label: "结算收票 - 供应商",
        },
        {
          value: "RETAILER",
          label: "结算收票 - 零售商",
        },
        {
          value: "ISSUE",
          label: "系统开具",
        },
        {
          value: "INCOME",
          label: "进项推送",
        },
        {
          value: "SALES",
          label: "销项推送",
        },
        {
          value: "VERIFY",
          label: "查验推送",
        },
        {
          value: "WECHAT",
          label: "微信卡包",
        },
        {
          value: "FENG_LIAN",
          label: "峰连科技",
        },
        {
          value: "MANUNAL",
          label: "手工录入",
        },
      ],
      IfRemarkList: [
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      IfDownloadList: [
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      imgPre: "",
      isShowPhoto: false,
      activeName: "master",
      invoiceImportVisible: false, // 导入收票弹窗
      dialogGoodsListVisible: false,
      currentGoodsIndex: 0,
      goodsList: [], 
      invoiceId: "",
      tips: "匹配：发票中的购方名称、税号与系统维护的组织信息一致<br>不匹配：发票中的购方名称、税号与系统维护的组织信息不一致<br>",
      columns: {
        invoiceType: {
          label: "发票类型",
          visible: true,
        },
        invoiceCode: {
          label: "发票代码",
          visible: true,
        },
        invoiceNo: {
          label: "发票号码",
          visible: true,
        },
        eInvoiceNo: {
          label: "数电发票号码",
          visible: true,
        },
        sumAmount: {
          label: "价税合计",
          visible: true,
        },
        amount: {
          label: "金额",
          visible: true,
        },
        taxAmount: {
          label: "税额",
          visible: true,
        },
        buyerName: {
          label: "购方名称",
          visible: true,
        },
        buyerInfoAudit: {
          label: "购方信息稽核",
          visible: true,
        },
        sellerName: {
          label: "销方名称",
          visible: true,
        },
        invoiceTime: {
          label: "开票日期",
          visible: true,
        },
        receiveTime: {
          label: "收票时间",
          visible: true,
        },
        verifyStatus: {
          label: "发票查验平台查验状态",
          visible: true,
        },
        remark: {
          label: "备注",
          visible: true,
        },
        invoiceStatus: {
          label: "发票状态",
          visible: true,
        },
        auditStatus: {
          label: "稽核状态",
          visible: true,
        },
        deductionStatus: {
          label: "抵扣状态",
          visible: true,
        }, 
        batchNo: {
          label: "识别批次",
          visible: true,
        },
        imageType: {
          label: "影像类型",
          visible: true,
        },
        orgName: {
          label: "上传组织",
          visible: true,
        },
        creatorName: {
          label: "上传者",
          visible: true,
        },
        invoiceExplain: {
          label: "发票说明",
          visible: true,
        },
        ifDownload: {
          label: "是否下载",
          visible: true,
        },
        sender: {
          label: "发件人",
          visible: true,
        },
        senderEmail: {
          label: "发件邮箱",
          visible: true,
        },
        receiveTimeS: {
          label: "收件时间",
          visible: true,
        },
        channel: {
          label: "收票类型",
          visible: true,
        },
        owneriod: {
          label: "所属周期",
          visible: true,
        },
        checkedTime: {
          label: "勾选日期",
          visible: true,
        },
        matchStatus: {
          label: "匹配状态",
          visible: true,
        },
        receiveFrom: {
          label: "发票来源",
          visible: true,
        },
        projectName: {
          label: "项目名称",
          visible: true,
        },
        voucherNo: {
          label: "凭证编号",
          visible: true,
        },
        reimburseDismissal: {
          label: "驳回说明",
          visible: true,
        },
        updated: {
          label: "更新时间",
          visible: true,
        },
        operate: {
          label: "操作",
          visible: true,
        },
      },
      receiveFromList: [
        { value: "", label: "全部" },
        { value: 1, label: "销项发票" },
        { value: 2, label: "进项发票" },
      ],
      isIndeterminate: true,
      checkAll: false,
      deductionStatusArr: [],
      buyerInfoAuditArr: [],
      auditStatusArr: [],
      search: {
        invoiceLabel: "ordinary",
        invoiceCode: undefined,
        invoiceNo: undefined,
        batchNo: undefined,
        verifyStatus: undefined,
        incomeVerifyStatus: undefined,
        invoiceTime: undefined,
        receiptDate: undefined,
        invoiceTypeList: undefined,
        sellerName: undefined,
        buyerName: undefined,
        buyerInfoAudit: undefined,
        invoiceIds: [],
        columns: [],
        exportItems: false,
        isVatInvoice: true,
        page: 1,
        size: 15,

        ifDownload: undefined,
        ifHasRemark: undefined,
        remark: undefined,
        sender: undefined,
        senderEmail: undefined,

        channel: undefined,
        eInvoiceNo: undefined,
        invoiceStatus: undefined,

        isCheck: undefined,
        deductionStatus: undefined,
        receiveFrom: undefined,
        matchStatus: undefined,
        classify: "GR",
      },
      total: 0,
      tableData: [],
      invoiceStatus: [],
      invoiceStatusList: [
        { label: "蓝票", value: "0" },
        { label: "红票", value: "1" },
        { label: "全额冲红", value: "2" },
        { label: "作废", value: "3" },
        { label: "部分冲红", value: "4" },
      ],
      manualVisible: false, 
      phoneVisible: false,
      tableLoading: false,
      verifyStatusSet: [],
      invoiceTypeSet: MANUAL_INVOICE_TYPES,
      invoiceTypeAllSet: [],
      manualInvoiceTypes: MANUAL_INVOICE_TYPES,
      qrCodeImg: "",
      /* 票据详情数据区 */
      drawer: false,
      loading: false,
      invoice: {},
      /* 票据影像数据区 */
      invoiceImageViewer: false,
      invoiceImages: [],
      /* 票据清单删除数据区 */
      invoiceListDelete: {},
      InvoiceListDeleteVisible: false,
      exportQuantity: 0,
      /* 票据修改数据区 */
      updateVisible: false,
      invoiceUpdate: {
        id: "",
        invoiceType: "",
        invoiceCode: "",
        invoiceNo: "",
        amount: "",
        checkCode: "",
        verifyCode: "",
        invoiceTime: "",
        buyerTaxNo: "",
        invoiceLabel: "ordinary",
        invoiceStatus: "",
        eInvoiceNo: "",
      },
      /* 编辑发票说明 */
      editVisibleExplain: false,
      explainWarning: false,
      invoiceExplain: "",
      invoiceExplainUpdateIds: [],
      /* 批量变更结算状态区 */
      isBatch: false,
      batchGeneratePdfTitle: "生成影像确认",
      batchGeneratePdfContent: "",
      batchGeneratePdfIds: [],
      batchGeneratePdfVisible: false,
      needToGeneratePdfCount: 0,
      currency: {
        currencyCash: 0,
        verifySpend: 0,
        verifySwitch: "N",
        recognizeSpend: 0,
        recognizeSwitch: "Y",
      },
      editableTabsValue: "",
      generatePdfData: {},
      pickerDisabled: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        },
      },
      /* 票据删除数据区 */
      auditBatchIds: [],
      auditWindow: false,
      selections: [],
      deleteSelectionsClass: "batch-deleted",
      selectExpended: false,
      invoiceBatchDeleteVisible: false,
      invoiceBatchPushVisible: false,
      downLoading: false,
      exportLoading: false,
      innerVisible: false,
      invoiceListData: [],
      invoiceListPageSize: 0,
      invoiceListUnmatched: [],
      invoiceListUpdateData: {},
      /* 导出设置弹窗 */
      exportColumns: [],
      colInvoiceId: "",
    };
  },
  watch: {
    sortOrder: {
      handler(val) {
        // console.log(val);
        if (val == "1") {
          this.invoiceListData = this.invoiceListData.sort((a, b) => {
            return a.id - b.id;
          });
        } else {
          this.invoiceListData = this.invoiceListData.sort((a, b) => {
            return a.pageIndex - b.pageIndex;
          });
        }
      },
    },
    updateVisible(val) {
      if (!val) {
        this.imgPre = "";
      }
    },
    "$route.path": {
      handler() {
        this.handleQuery();
      },
      immediate: true,
    },
    "$store.state.isCollapse": {
      handler() {
        this.deleteSelectionsClass = this.$store.state.isCollapse
          ? "batch-deleted-hidden"
          : "batch-deleted";
      },
      immediate: true,
    },
  },
  computed: {
    isInvoiceList() {
      let invoiceType = this.invoiceUpdate.invoiceType;
      return invoiceType === "VatInvoiceList";
    },
    hasAmount() {
      return (value) => {
        let invoiceType = value.invoiceType;
        return invoiceType == "GenerInvoice" ||
          invoiceType == "EleInvoice" ||
          invoiceType == "EleSpecInvoice" ||
          invoiceType == "EleTollInvoice" ||
          invoiceType == "VolInvoice"
          ? false
          : true;
      };
    },
    hasCheckCode() {
      return (value) => {
        let invoiceType = value.invoiceType;
        return invoiceType == "SpecInvoice" || invoiceType == "VehInvoice"
          ? false
          : true;
      };
    },
  },
  created() {
    this.handleInitDictionaries();
    this.verifyStatusSet = [
      { label: "全部", value: undefined },
      ...this.$store.state.enums.InvoiceVerifyStatus,
    ];
    this.invoiceTypeAllSet = [
      { label: "全部", value: undefined },
      ...this.invoiceTypeSet,
    ];
    this.invoiceTypeAllSet.forEach((item) => {
      item.disabled = false;
    });
  },
  methods: {
    invoiceTypeSetChange() {
      // console.log(this.invoiceTypeAllSet, "---invoiceTypeAllSet----");
      if (this.search.invoiceTypeList.length == 0) {
        this.invoiceTypeAllSet.forEach((item) => {
          item.disabled = false;
        });
      }
      this.search.invoiceTypeList.forEach((item) => {
        if (item == undefined) {
          this.invoiceTypeAllSet.forEach((item) => {
            item.disabled = true;
          });
          this.invoiceTypeAllSet[0].disabled = false;
        } else {
          this.invoiceTypeAllSet[0].disabled = true;
        }
      });
    }, 
    //列设置全选
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      let cols = this.columns;
      for (let p in cols) {
        cols[p].visible = val;
      }
      this.upInsertColumnSetting("SPZX", JSON.stringify(this.columns));
    },
    //列设置改变
    handleCheckedCitiesChange(pk, pv) {
      Object.keys(this.columns).some((key) => {
        if (pk == key) {
          this.columns[key].visible = pv.visible;
          return true;
        }
      });
      this.upInsertColumnSetting("SPZX", JSON.stringify(this.columns));
    }, 
    // 表格合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "共:";
          return;
        }
        if (index === 1) {
          sums[index] = this.selections.length + "张";
          return;
        }
        const values = this.selections.map((item) =>
          Number(item[column.property])
        );
        if (
          !values.every((value) => isNaN(value)) &&
          (column.label === "金额" ||
            column.label === "税额" ||
            column.label === "价税合计")
        ) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return Number((prev + curr).toFixed(2));
            } else {
              return prev;
            }
          }, 0);
          if (column.label === "金额") {
            sums[index] = "金额：" + sums[index];
          } else if (column.label === "税额") {
            sums[index] = "税额：" + sums[index];
          } else if (column.label === "价税合计") {
            sums[index] = "价税合计：" + sums[index];
          }
        } else {
          if (column.label === "金额") {
            sums[index] = "金额：" + 0;
          } else if (column.label === "税额") {
            sums[index] = "税额：" + 0;
          } else if (column.label === "价税合计") {
            sums[index] = "价税合计：" + 0;
          }
        }
      });
      return sums;
    }, 
    /* 收票中心数据列表 */
    async handleGetInvoiceData() {
      this.tableLoading = true;
      const { success, data } = await getVATInvoiceCenterByPage(this.search);
      if (!success) {
        this.toast("发票中心数据获取失败！", "error");
        this.tableLoading = false;
        return;
      }
      // 查询结果处理
      this.total = data.total;
      // console.log(this.tableData, '----this.tableData-----');
      this.tableData = data.records.map((item) => {
        this.$set(item, "loading", false);
        return item;
      });
      this.tableLoading = false;
    },
    /* 表格值格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case "发票类型":
          return this.handleValueToLabel("InvoiceType", value);
        case "金额":
        case "税额":
        case "价税合计":
          if (value == null) return "￥" + "0";
          return "￥" + value;
        case "影像类型":
          return this.handleValueToLabel("InvoiceImageType", value);
        // case '发票来源':
        //   return this.handleValueToLabel('InvoiceReceiveFrom', value);
        case "识别批次":
          if (value) return value;
          return "--";
        case "备注":
          if (value) return value;
          return "--";
        case "上传组织":
        case "发件邮箱":
        case "上传者":
          if (value) return value;
          return "--";
        case "发件人":
          if (value) return value;
          return "--";
        case "发件人邮箱":
          if (value) return value;
          return "--";
        case "数电发票号码":
          if (value) return value;
          return "--";
        case "收件时间":
          if (value) return value;
          return "--";
        case "收票类型":
          if (value != null && value != "") {
            return this.getDictLabel(this.TypeOfReceiptList, value);
          }
          return "--";
        case "匹配状态":
          if (value == "0") {
            return "未匹配";
          } else if (value == "1") {
            return "已匹配";
          } else if (value == "2") {
            return "匹配失败";
          }
          return "--";
        case "发票来源":
          if (value === 1) {
            return "销项发票";
          } else if (value === 2) {
            return "进项发票";
          } else {
            return "--";
          }
        case "开票日期":
          if (value != undefined) {
            return this.$moment(value).format("YYYY-MM-DD");
          }
          return "--"; 
        case "稽核状态":
          if (value != null && value != "") {
            return this.getDictLabel(this.auditStatusArr, value);
          }
          return "--";
        case "抵扣状态":
          if (value != null && value != "") {
            return this.getDictLabel(this.deductionStatusArr, value);
          }
          return "--";
        case "发票说明":
          if (value) return value;
          return "--";
        case "发票状态":
          if (value != null && value != "") {
            return this.getDictLabel(this.invoiceStatusList, value);
          }
          return "--";
        case "销方名称":
          if (value != null && value != "") {
            return value;
          }
          return "--";
        case "购方名称":
          if (value != null && value != "") {
            return value;
          }
          return "--";
        case "购方信息稽核":
          if (value != null && value != "") {
            return this.getDictLabel(this.buyerInfoAuditArr, value);
          }
          return "--";
        default:
          return value;
      }
    },
    /* 初始化字典值 */
    handleInitDictionaries() {
      this.batchGetDicts({
        types: [
          "invoice_status",
          "settlement_status",
          "audit_status",
          "deduction_status",
          "buyer_info_audit",
          "sys_normal_yes_no",
        ],
      }).then(({ success, data }) => {
        if (success) {
          // 发票税控状态
          this.invoiceStatus = [
            {
              label: "全部",
              value: undefined,
            },
            ...data["invoice_status"],
          ]; 
          // 发票稽核状态
          this.auditStatusArr = [
            {
              label: "全部",
              value: undefined,
            },
            ...data["audit_status"],
          ];

          // 发票抵扣状态
          this.deductionStatusArr = [
            {
              label: "全部",
              value: undefined,
            },
            ...data["deduction_status"],
          ];

          // 购方信息稽核
          this.buyerInfoAuditArr = [
            {
              label: "全部",
              value: undefined,
            },
            ...data["buyer_info_audit"],
          ];

        }
      });
    },
    /* 展开筛选列表 */
    handleListShow() {
      this.selectExpended = true;
    },
    /* 收起筛选列表 */
    handleListClose() {
      this.selectExpended = false;
    },
    /* 重置查询 */
    handleReset() {
      this.search = {
        invoiceLabel: "ordinary",
        invoiceCode: undefined,
        invoiceNo: undefined,
        batchNo: undefined,
        verifyStatus: undefined,
        invoiceTime: undefined,
        invoiceType: undefined,
        isVatInvoice: true,
        page: 1,
        size: 15,
        ifDownload: undefined,
        ifHasRemark: undefined,
        remark: undefined,
        sender: undefined,
        senderEmail: undefined,

        channel: undefined,
        eInvoiceNo: undefined,
        invoiceStatus: undefined,

        isCheck: undefined,
        deductionStatus: undefined,
        receiveFrom: undefined,
        matchStatus: undefined,
      };
      this.invoiceTypeAllSet.forEach((item) => {
        item.disabled = false;
      });
    },
    /* 条件查询 */
    handleQuery() {
      this.handleGetInvoiceData();
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value;
      this.selections.forEach((item) => {
        item.invoiceId = item.id;
      });
      // console.log(this.selections, '---this.selections----this.selections');
    }, 
    /* 发票详情 */
    handleViewDetails(invoice) {
      this.drawer = !this.drawer;
      this.loading = true;
      selectInvoice(invoice.id).then((res) => {
        if (res.success) {
          this.loading = false;
          let details = res.data.items;
          if (details) {
            details.forEach(
              (item) => (item.taxRate = Number(item.taxRate) * 100 + "%")
            );
            // 统计信息
            let total = {
              merchandiseName: "合计",
              amount: invoice.amountString
                ? invoice.amountString
                : invoice.amount,
              taxAmount: invoice.taxAmountString
                ? invoice.taxAmountString
                : invoice.taxAmount,
            };
            details.push(total);
            let sum = Number(invoice.sumAmount).toFixed(2);
            let sumBig = this.handleSmallToCapitalize(sum);
            let priceTax = {
              merchandiseName: "价税合计（大写）",
              specification: sumBig + "  （小写）￥" + sum,
            };
            details.push(priceTax);
          }
          let invoiceData = res.data;
          invoiceData.items = details;
          this.invoice = invoiceData;
        }
      });
    },
    // 之前影像
    handleInvoiceImg(invoice, isLook = true) {
      invoice.loading = true;
      getInvoiceImageListById(invoice.id).then((res) => {
        if (res.success) {
          if (isLook) {
            this.invoiceImageViewer = true;
          }
          this.imgPre = res.data[0];
          this.invoiceImages = res.data;
        }
        invoice.loading = false;
      });
    }, 
    /* 发票影像弹窗关闭 */
    handleImageViewerClose() {
      this.invoiceImageViewer = false;
      this.invoiceImages = [];
    }, 
    /* 验真状态颜色 */
    handleVerifyAndSealStatusColor(status) {
      switch (status) {
        case "FAILURE":
        case "VF":
          return "#f5222d";
        case "VS":
          return "#09bb07";
        case "CHECKING":
          return "#FF7A08";
        default:
          return "#666666";
      }
    },
    /* 切换分页条数 */
    handleSizeChange(value) {
      this.search.size = value;
      this.handleQuery();
    },
    // 翻页
    handleCurrentChange(value) {
      this.search.page = value;
      this.handleQuery();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/select.scss";
.tabs_box {
  position: relative;
}
.select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      color: #3d94ff;
      margin-right: 20px;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;

      img {
        width: 20px;
        height: 20px;
        vertical-align: sub;
        margin-right: 4px;
      }
    }

    div:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .el-button {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1890ff;
        line-height: 22px;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 2px;
          margin-left: 20px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }

  .content-table {
    .el-button {
      padding: 0;
    }
  }
}

.manual-input {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;

    ::v-deep .el-select > .el-input,
    .el-input {
      width: 250px;
    }
  }
}

.phone-scan {
  .phone-scan-text {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-bottom: 12px;
    }
  }

  .phone-scan-img {
    .el-image {
      width: 260px;
      height: 260px;
    }
  }
}

.invoice-update {
  margin: 24px;
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}

.el-image-viewer {
  position: absolute;
  z-index: 1000;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  margin-bottom: 24px;

  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }

  .message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}

.dialog-delete {
  display: flex;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-invoice-list {
  .list-statistics {
    text-align: left;

    .el-row {
      margin-bottom: 12px;
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .list-table {
    margin-top: 12px;

    div {
      text-align: left;
      margin-bottom: 12px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .el-button {
      padding: 0;
    }
  }
}

.dialog-settlement {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  text-align: left;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0px 24px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-dialog__footer {
  padding: 0px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

.invoice-deleted {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 200px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.invoice-deleted-hidden {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 64px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.dialog-wrap {
  span {
    color: #333333;
  }

  .deletes-btns {
    padding-top: 24px;
    text-align: right;
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.icon {
  color: #ff0000;
  margin-left: 3px;
}
.type_invoice {
  ::v-deep .el-select__tags {
    flex-wrap: nowrap;
    max-width: 88px;
  }
}
.button-footer {
  text-align: center;

  .button-left {
    margin-right: 65px;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 10px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
.kp_title {
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
}
.invoiceImageVisibleHeight {
  ::v-deep .el-dialog {
    height: 100%;
    margin: 0;
    margin-top: 1vh;
  }
  ::v-deep .el-dialog__headerbtn {
    font-size: 26px;
  }
}
.order_sort {
  display: flex;
  justify-content: space-between;
}
</style>
<style lang="scss">
.incvoice_open_goods_list {
  table {
    width: 100%;
  }
}
.el-form--inline .el-form-item {
  margin: 10px 10px 10px 0;
}
.select-button {
  min-width: 200px;
  margin-left: 30px;
}
</style>
