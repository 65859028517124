<template>
  <div
    v-loading="attachmentListLoading"
    style="height: 600px; padding: 40px 20px 40px 20px"
  >
    <div v-if="!attachmentListLoading && !hasAttachment">
      <h3>该张发票暂无影像文件！</h3>
    </div>
    <el-tabs
      v-if="!attachmentListLoading"
      @tab-click="handlePreviewFile"
      v-model="activePaneName"
      tab-position="left"
    >
      <el-tab-pane
        v-for="(attachment, index) of attachmentList"
        :key="attachment.file.id"
        :name="tabPanePrefix + index"
      >
        <el-tooltip
          slot="label"
          :content="attachment.file.fileName"
          placement="right"
          effect="light"
        >
          <span>
            <i
              v-if="attachment.file.fileType == 'PDF'"
              class="el-icon-document"
            ></i>
            <i
              v-if="attachment.file.fileType == 'OFD'"
              class="el-icon-tickets"
            ></i>
            <i
              v-if="attachment.file.fileType == 'IMG'"
              class="el-icon-picture-outline"
            ></i>
            <i
              v-if="attachment.file.fileType == 'XML'"
              class="el-icon-notebook-1"
            ></i>
            {{ attachment.file.fileType }}</span
          >
        </el-tooltip>
        <iframe
          v-if="attachment.file.fileType == 'PDF'"
          :src="attachment.file.filePath"
          style="
            overflow: auto;
            height: 600px;
            width: 90%;
            border: 1px solid;
            text-align: left;
          "
        ></iframe>
        <div
          v-if="attachment.file.fileType == 'OFD'"
          style="
            overflow: auto;
            height: 600px;
            width: 90%;
            border: 1px solid;
            text-align: left;
          "
          :id="'OFD_' + index"
        ></div>
        <div
          v-if="attachment.file.fileType == 'XML'"
          style="
            overflow: auto;
            height: 600px;
            width: 90%;
            border: 1px solid;
            text-align: left;
          "
        >
          <pre><code class="language-xml" v-html="xmlFileContent"></code></pre>
        </div>
        <div
          v-if="attachment.file.fileType == 'IMG'"
          style="overflow: auto; height: 600px; width: 100%"
        >
          <el-image
            :src="attachment.file.filePath"
            style="
              width: 90%;
              height: 90%;
              border-radius: 5px;
              border: 1px solid #cccccc;
            "
            :preview-src-list="[attachment.file.filePath]"
            :lazy="true"
          ></el-image>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Prism from "prismjs";
import "prismjs/themes/prism.css";
import { parseOfdDocument, renderOfd } from "ofd.js";
import { reqAttachmentByColInvoiceId } from "@/service/invoice-center";

export default {
  name: "ColInvoiceImage",
  props: ["colInvoiceId"],
  data() {
    return {
      attachmentList: [],
      attachmentListLoading: false,

      tabPanePrefix: "file-",
      activePaneName: "file-0",

      xmlFileContent: undefined,
    };
  },
  computed: {
    hasAttachment() {
      return this.attachmentList.length > 0;
    },
  },
  watch: {
    colInvoiceId: {
      handler() {
        this.getAttachments();
      },
    },
    deep: true,
  },
  created() {
    this.getAttachments();
  },
  methods: {
    // 获取发票附件
    async getAttachments() {
      this.attachmentListLoading = true;
      let { success, data } = await reqAttachmentByColInvoiceId(
        this.colInvoiceId
      );
      if (success) {
        data.forEach((item) => {
          item.file.filePath = item.file.filePath.replace(
            "http://",
            "https://"
          );
        });
        this.attachmentList = data;
        if (this.attachmentList.length > 0) {
          this.handlePreviewFile();
        }
      }
      this.attachmentListLoading = false;
    },
    // 浏览发票附件
    handlePreviewFile() {
      let index = parseInt(this.activePaneName.replace(this.tabPanePrefix, ""));
      let attachment = this.attachmentList[index];
      if (attachment.file.fileType == "XML") {
        // console.log("attachment.file.fileType == 'XML'");
        this.previewXmlFile(attachment);
      }
      if (attachment.file.fileType == "OFD") {
        // console.log("attachment.file.fileType == 'XML'");
        this.previewOfdFile(attachment, index);
      }
    },

    // 浏览XML发票附件
    async previewXmlFile(attachment) {
      let that = this;
      try {
        const response = await fetch(attachment.file.filePath);
        const xmlContent = await response.text();
        this.xmlFileContent = Prism.highlight(
          xmlContent,
          Prism.languages.markup,
          "xml"
        );
        // console.log("this.xmlFileContent:", this.xmlFileContent);
      } catch (error) {
        // console.error("浏览发票XML文件时出错！", error);
        that.toast("浏览发票XML文件时出错！", "error");
      }
    },
    // 浏览OFD发票附件
    previewOfdFile(attachment, index) {
      let that = this;
      parseOfdDocument({
        ofd: attachment.file.filePath,
        success(res) {
          if (res) {
            let screenWidth = 1600;
            const divs = renderOfd(screenWidth, res[0]);
            let contentDiv = document.getElementById("OFD_" + index);
            contentDiv.innerHTML = "";
            for (const div of divs) {
              contentDiv.appendChild(div);
            }
          }
        },
        fail(error) {
          // console.log("浏览发票OFD文件时出错！", error);
          that.toast("浏览发票OFD文件时出错！", "error");
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
