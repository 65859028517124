<template>
    <!--出租车票-->
    <div>
        <el-form :model="invoiceData" label-width="120px">
          <el-form-item label="发票类型">
            <el-input disabled v-model="invoiceData.invoiceTypeName"></el-input>
          </el-form-item>
            <el-form-item label="发票代码">
                <el-input v-model="invoiceData.invoiceCode"></el-input>
            </el-form-item>
            <el-form-item label="发票号码">
                <el-input v-model="invoiceData.invoiceNo"></el-input>
            </el-form-item>
            <el-form-item label="开票日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="invoiceData.invoiceTime" type="date"  :clearable="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="金额">
                <el-input-number v-model="invoiceData.sumAmount" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="里程">
                <el-input v-model="invoiceData.kilometre"></el-input>
            </el-form-item>
            <el-form-item label="发票归属地">
                <el-input v-model="invoiceData.invoicePlace"></el-input>
            </el-form-item>
            <el-form-item label="上车时间">
                <el-input v-model="invoiceData.onTime"></el-input>
            </el-form-item>
            <el-form-item label="下车时间">
                <el-input v-model="invoiceData.offTime"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="invoiceData.note"></el-input>
            </el-form-item>
            <el-form-item label="发票说明">
                <el-input v-model="invoiceData.invoiceExplain"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    name: "taxiInvoiceForm",
    props: ["invoiceData"]

}
</script>
<style scoped>
</style>
