<template>
    <!--增值税普通发票（卷票)-->
    <div>
        <el-form :model="invoiceData" label-width="120px">
          <el-form-item label="发票类型">
            <el-input disabled v-model="invoiceData.invoiceTypeName"></el-input>
          </el-form-item>
            <el-form-item label="发票代码">
                <el-input v-model="invoiceData.invoiceCode"></el-input>
            </el-form-item>
            <el-form-item label="发票号码">
                <el-input v-model="invoiceData.invoiceNo"></el-input>
            </el-form-item>
            <el-form-item label="开票日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="invoiceData.invoiceTime" type="date"  :clearable="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="校验码">
                <el-input v-model="invoiceData.checkCode"></el-input>
            </el-form-item>
            <el-form-item label="价税合计">
                <el-input-number v-model="invoiceData.sumAmount" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="购买方名称">
                <el-input v-model="invoiceData.buyerName"></el-input>
            </el-form-item>
            <el-form-item label="购买方税号">
                <el-input v-model="invoiceData.buyerTaxNo"></el-input>
            </el-form-item>
            <el-form-item label="销售方名称">
                <el-input v-model="invoiceData.sellerName"></el-input>
            </el-form-item>
            <el-form-item label="销售方税号">
                <el-input v-model="invoiceData.sellerTaxNo"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="invoiceData.remark"></el-input>
            </el-form-item>
            <el-form-item label="发票说明">
                <el-input v-model="invoiceData.invoiceExplain"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    name: "rollInvoiceForm",
    props: ["invoiceData"]

}
</script>
<style scoped>
</style>
