<template>
  <el-drawer v-loading="loading" element-loading-text="加载中，请稍后" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)" :with-header="false" :show="show" :visible.sync="drawerVisible" @close="$emit('update:show', false)" direction="rtl" size="789px">
    <div class="invoice-title">发票详情</div>
    <div class="invoice-top">
      <el-row class="top-first-row">主要信息</el-row>
      <el-row class="top-first-row">
        <el-col :span="8">
          <label>发票类型：</label>
          <span>{{ handleValueToLabel('InvoiceType', invoice.invoiceType) }}</span>
        </el-col>
        <el-col :span="8">
          <label>发票号码：</label>
          <span>{{ invoice.invoiceNo }}</span>
        </el-col>
        <el-col :span="8">
          <label>发票代码：</label>
          <span>{{ invoice.invoiceCode }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="8">
          <label>开票日期：</label>
          <span>{{ handleDateFormat(invoice.invoiceTime) }}</span>
        </el-col>
        <el-col :span="8">
          <label>数电票号：</label>
          <span>{{ invoice.eInvoiceNo }}</span>
        </el-col>
        <el-col :span="8">
          <label>未税金额：</label>
          <span>{{ invoice.amount }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="8">
          <label>发票税额：</label>
          <span>{{ invoice.taxAmount }}</span>
        </el-col>
        <el-col :span="8">
          <label>价税合计：</label>
          <span>{{ invoice.sumAmount }}</span>
        </el-col>
        <el-col :span="8">
          <label>发票税率：</label>
          <span>{{ invoice.taxRate }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <!-- <el-col :span="8">
          <label>独立清单：</label>
          <span>{{ invoice.isExistList ? '是' : '否' }}</span>
        </el-col>
        <el-col :span="8">
          <label>发票联次：</label>
          <span>{{ invoice.imageDeductionChineseKey }}</span>
        </el-col> -->
        <el-col :span="8">
          <label>发票备注：</label>
          <span>{{ invoice.remark }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="8">
          <label>发票说明：</label>
          <span>{{ invoice.invoiceExplain }}</span>
        </el-col>
      </el-row>
    </div>

    <div class="invoice-top" style="margin-top: 26px">
      <el-row class="top-first-row">审核信息</el-row>
      <el-row class="top-first-row">
        <el-col :span="8">
          <label>开票人：</label>
          <span>{{ invoice.payer }}</span>
        </el-col>
        <el-col :span="8">
          <label>复核人：</label>
          <span>{{ invoice.auditor }}</span>
        </el-col>
        <el-col :span="8">
          <label>收款人：</label>
          <span>{{ invoice.payee }}</span>
        </el-col>
      </el-row>
      <!-- <el-row class="top-first-row">
        <el-col :span="8">
          <label>是否盖章(发票联)：</label>
          <span>{{ invoice.invoiceSeal ? '已盖章' : '未盖章' }}</span>
        </el-col>
        <el-col :span="8">
          <label>是否盖章(抵扣联)：</label>
          <span>{{ invoice.invoiceDeductionSeal ? '已盖章' : '未盖章' }}</span>
        </el-col>
        <el-col :span="8">
          <label>印刷代码：</label>
          <span>{{ invoice.printedBrushCode }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="8">
          <label>打印代码：</label>
          <span>{{ invoice.printedCode }}</span>
        </el-col>
        <el-col :span="8">
          <label>印刷号码：</label>
          <span>{{ invoice.printedBrushNo }}</span>
        </el-col>
        <el-col :span="8">
          <label>打印号码：</label>
          <span>{{ invoice.printedNo }}</span>
        </el-col>
      </el-row> -->
    </div>

    <div class="invoice-top" style="margin-top: 26px">
      <el-row class="top-first-row">其他信息</el-row>
      <el-row class="top-first-row">
        <!-- <el-col :span="8">
          <label>是否启用发票查验平台：</label>
          <span>{{ invoice.isUseVerifyPlatform ? '是' : '否' }}</span>
        </el-col> -->
        <el-col :span="8">
          <label>发票查验平台查验状态：</label>
          <span>{{ handleValueToLabel('InvoiceVerifyStatus', invoice.verifyStatus) }}</span>
        </el-col>
        <el-col :span="8">
          <label>查验日期：</label>
          <span>{{ handleDateFormat(invoice.created) }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <!-- <el-col :span="8">
          <label>是否启用综合服务平台：</label>
          <span>{{ invoice.isUseIncomeVerifyPlatform ? '是' : '否' }}</span>
        </el-col>
        <el-col :span="8">
          <label>综合服务平台查验状态：</label>
          <span>
            <span>{{ handleValueToLabel('InvoiceVerifyStatus', invoice.incomeVerifyStatus) }}</span>
          </span>
        </el-col> -->
        <el-col :span="8">
          <label>抵扣状态：</label>
          <span>{{ invoice.deductionStatus }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="8">
          <label>发票来源：</label>
          <span>{{ handleInvoiceSource(invoice.source) }}</span>
        </el-col>
        <el-col :span="8">
          <label>上传者：</label>
          <span>{{ invoice.creatorName }}</span>
        </el-col>
        <el-col :span="8">
          <label>收票时间：</label>
          <span>{{ invoice.receiveTime }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="8">
          <label>回传成功：</label>
          <span>{{ handleInvoicePush(invoice.retturnSuccess) }}</span>
        </el-col>
        <el-col :span="8">
          <label>回传响应：</label>
          <span>{{ handleInvoicePush(invoice.retturnMessage) }}</span>
        </el-col>
      </el-row>
    </div>
    <div class="invoice-top" style="margin-top: 26px">
      <el-row class="top-first-row">开票信息</el-row>
      <el-row class="top-first-row">
        <el-col :span="12">
          <span>购买方</span>
        </el-col>
        <el-col :span="12">
          <span>销售方</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="12">
          <label>名称：</label>
          <span>{{ invoice.buyerName }}</span>
        </el-col>
        <el-col :span="12">
          <label>名称：</label>
          <span>{{ invoice.sellerName }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="12">
          <label>税号：</label>
          <span>{{ invoice.buyerTaxNo }}</span>
        </el-col>
        <el-col :span="12">
          <label>税号：</label>
          <span>{{ invoice.sellerTaxNo }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="12">
          <label>地址电话：</label>
          <span>{{ invoice.buyerAddressTel }}</span>
        </el-col>
        <el-col :span="12">
          <label>地址电话：</label>
          <span>{{ invoice.sellerAddressTel }}</span>
        </el-col>
      </el-row>
      <el-row class="top-first-row">
        <el-col :span="12">
          <label>银行账号：</label>
          <span>{{ invoice.buyerBankAccount }}</span>
        </el-col>
        <el-col :span="12">
          <label>银行账号：</label>
          <span>{{ invoice.sellerBankAccount }}</span>
        </el-col>
      </el-row>
    </div>
    <div class="invoice-top" style="margin-top: 26px; margin-bottom: 100px; border: none">
      <el-row class="top-first-row">开票明细</el-row>

      <div class="matching_tax_classification">
        <el-checkbox-group  size="mini" @change="hanlderCodingName" v-model="checkboxGroup1">
        <el-checkbox-button style="margin-right:0" v-for="city in cityOptions" :label="city" :key="city">{{city}}</el-checkbox-button>
      </el-checkbox-group>
      </div>

      <div class="bottom-table">
        <el-table :data="invoice.items" :span-method="arraySpanMethod" :header-cell-style="handleHeaderCellStyle" border stripe min-height="200">
          <el-table-column label="项目名称" prop="merchandiseName" min-width="200" show-overflow-tooltip fixed="left" />
          <el-table-column label="规格型号" prop="specification" min-width="120" show-overflow-tooltip />
          <el-table-column label="单位" prop="unit" min-width="120" show-overflow-tooltip />
          <el-table-column label="数量" prop="quantity" min-width="120" header-align="right" align="right" show-overflow-tooltip />
          <el-table-column label="单价" prop="unTaxPrice" min-width="120" header-align="right" align="right" show-overflow-tooltip />
          <el-table-column label="金额" prop="amount" min-width="140" header-align="right" align="right" show-overflow-tooltip />
          <el-table-column label="税率" min-width="120" header-align="right" align="right">
            <template slot-scope="scope">
              <span v-if="scope.row.taxRateString">{{ scope.row.taxRateString }}</span>
              <span v-else>{{ scope.row.taxRate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税额" min-width="120" header-align="right" align="right">
            <template slot-scope="scope">
              <span v-if="scope.row.taxAmountString">{{ scope.row.taxAmountString }}</span>
              <span v-else>{{ scope.row.taxAmount }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="showCode"  label="税分编码" prop="taxClassCode" min-width="200" show-overflow-tooltip />
          <el-table-column v-if="showName"  label="税分名称" prop="taxClassName" min-width="160" show-overflow-tooltip />
        </el-table>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { tableStyle, changeMethods } from '@/util/mixins';

export default {
  mixins: [tableStyle, changeMethods],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    invoice: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      checkboxGroup1:[],
      cityOptions:['显示税分编码', '显示税分名称'],
      drawerVisible: this.show,
      invoiceStatus: [],
      returnSuccessEnums: [],
      invoiceSources: [],
      showCode:false,
      showName:false
    };
  },
  watch: {
    show() {
      this.drawerVisible = this.show;
    }
  },
  created() {
    this.handleInitDictionaries();
  },
  computed: {
    tableData() {
      return this.invoice.items;
    }
  },
  methods: {
    hanlderCodingName(val){
      if(val.length == 2){
        this.showCode = true,
        this.showName = true
      }else if (val.length == 1){
        val.forEach(element => {
          if(element == '显示税分编码'){
            this.showCode = true
            this.showName = false
          }else if(element == '显示税分名称'){
            this.showName = true
            this.showCode = false
          }
        });
      }else if (val.length == 0){
        this.showCode = false,
        this.showName = false
      }
    },
    arraySpanMethod({ rowIndex, columnIndex }) {
      // console.log(rowIndex, columnIndex,'rowIndex, columnIndex')
      const openDetailsLength = this.invoice.items.length;
      if (rowIndex === openDetailsLength - 1) {
        if (columnIndex === 0) {
          return [1, 1];
        } else if (columnIndex === 1) {
          return [1, 9];
        } else{
          return [0,0]
        }
      }
    },
    /* 时间格式化 */
    handleDateFormat(date) {
      if (date == undefined) {
        return '';
      }
      return this.$moment(date).format('YYYY-MM-DD');
    },
    handleInvoiceSource(value) {
      if (value != null && value != '') {
        return this.getDictLabel(this.invoiceSources, value);
      }
    },
    handleInvoiceStatus(value) {
      if (value != null && value != '') {
        return this.getDictLabel(this.invoiceStatus, value);
      }
    },
    handleInvoicePush(value) {
      if (value != null && value != '') {
        return this.getDictLabel(this.returnSuccessEnums, value);
      }
    },
    /* 初始化字典值 */
    handleInitDictionaries() {
      // 发票状态
      this.getDicts('invoice_status').then(
        ({ data }) =>
          (this.invoiceStatus = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );

      // 推送状态
      this.getDicts('sys_normal_yes_no').then(
        ({ data }) =>
          (this.returnSuccessEnums = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );

      // 发票来源
      this.getDicts('invoice_source').then(
        ({ data }) =>
          (this.invoiceSources = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );
    }
  }
};
</script>

<style scoped lang="scss">
.invoice-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
  margin: 24px 24px 32px 24px;
}

.invoice-top {
  margin: 0 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  position: relative;
  .matching_tax_classification{
    position: absolute;
    top: -2px;
    right: 14px;
    z-index: 1000;
  }
  label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-right: 4px;
  }

  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }

  .top-first-row {
    margin-bottom: 16px;

    div {
      color: #09bb07;
    }
  }
}

.invoice-middle {
  display: flex;
  margin: 32px 24px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #cccccc;

  .middle-average {
    width: 400px;

    .first-row {
      margin-bottom: 16px;

      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }

    .second-row {
      display: flex;
      margin-bottom: 16px;

      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-right: 4px;
      }

      div {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        text-align: left;
        margin-right: 20px;
      }
    }

    .other-row {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      margin-right: 20px;

      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-right: 4px;
      }

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}

.invoice-bottom {
  margin: 32px 24px;
  height: calc(100% - 225px);

  span {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }

  .bottom-table {
    margin-top: 16px;
    ::v-deep .el-table__body {
      width: 100%;
    }
  }
}

::v-deep .el-drawer__body {
  overflow: auto;
}

.content-select {
  margin-top: 24px;
  padding-bottom: 16px;
  box-shadow: none;

  .el-row {
    margin-bottom: 16px;

    span {
      color: #333333;
    }
  }

  .verifyStatus {
    color: #ff4d4f;
  }
}

.content-line {
  border-bottom: 1px solid #cccccc;
}

.parter-line {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}
</style>
