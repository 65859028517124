import { post, get, download, del } from './index.js'

/**
 * 进项发票数据获取
 * @param param
 * @returns
 */
export const getIncomeInvoicePoolData = param => {
  return get('/invoice/income/page', param).then(res => res)
}

/**
 * 进项发票数据获取
 * @param param
 * @returns
 */
export const getSalesInvoicePoolData = param => {
  return get('/invoice/sales/page', param).then(res => res)
}
/**
 * 我的票池导出
 * @param data
 */
export const exportInvoices = data => {
  return download('/invoice/export', data).then(res => res)
}
/**
 * 发票详情信息
 * @param param
 * @returns
 */
export const getInvoiceDetails = param => {
  return get('/invoice/details/' + param).then(res => res)
}

/**
 * 发票影像信息
 * @param param
 * @returns
 */
export const getInvoiceImage = param => {
  return get('/invoice/image/' + param).then(res => res)
}

/**
 * 进项发票删除
 * @param param
 * @returns
 */
export const deleteIncomeInvoiceData = param => {
  return del('/invoice/income/' + param).then(res => res)
}
