<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-header">
        <div class="select-title">
          <span>报销发票单</span>
        </div>
        <div class="select-items">
        </div>
      </div>
      <div class="select-content">
        <el-form ref="selectFrom" :model="search" inline label-width="160px">
          <el-form-item label="报销码">
            <el-input v-model.trim="search.batchNo" placeholder="请输入报销码" maxlength="18" @keyup.enter.native="handleGetInvoiceData()" clearable />
          </el-form-item>
          <el-form-item label="是否报销">
            <el-select
              v-model="search.isReimburse"
              placeholder="请选择"
              clearable
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="提报时间">
            <el-date-picker
              class="date-picker"
              v-model="search.receiptDate"
              :picker-options="dateOption"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
            >
            </el-date-picker>
          </el-form-item>
          <span v-show="selectExpended">
            <el-form-item label="提报人">
              <el-input v-model.trim="search.creatorName" placeholder="请输入提报人" maxlength="22" @keyup.enter.native="handleGetInvoiceData()" clearable />
            </el-form-item>
            <el-form-item label="归档状态">
              <el-select v-model="search.archivingStatus" placeholder="请选择" clearable>
                <el-option v-for="item in archivingStatusAllSet" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>          
            <el-form-item label="凭证编号">
              <el-input v-model.trim="search.voucherNo" placeholder="请输入凭证编号" maxlength="18" @keyup.enter.native="handleGetInvoiceData()" clearable />
            </el-form-item>
          </span>
          <el-form-item>
            <select-button show-status @list-close="handleListClose" @list-show="handleListShow" @select="handleQuery" @reset="handleReset"> </select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <div class="top-left">
            <span>票据列表</span>
            <el-popover placement="right" width="80" trigger="click">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选 </el-checkbox>
              <div class="part"></div>
              <el-checkbox v-for="(pv, pk) in columns" :label="pv.label" :key="pk" v-model="pv.visible" style="display: block; margin-top: 6px" @change="handleCheckedCitiesChange(pk, pv)" />
              <div slot="reference" class="rowSet">
                <img class="imgSet" src="@/assets/icon/set.png" />
                <span>列设置</span>
              </div>
            </el-popover>
          </div>
          <div>
          </div>
        </div>
        <div class="content-table">
          <el-table ref="invoiceCenterTable" :data="tableData" stripe border show-summary :summary-method="getSummaries" v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelection" style="width: 100%">
            <el-table-column type="selection" width="50" fixed="left" />
            <el-table-column type="index" label="序号" width="70" />
            <el-table-column v-if="columns['batchNo'].visible" prop="batchNo" :label="columns['batchNo'].label" width="180" :formatter="handleTableValueFormat" />
            <el-table-column
              v-if="columns['isReimburse'].visible"
              prop="isReimburse"
              :label="columns['isReimburse'].label"
              width="80"
            >
              <template slot-scope="scope">
                <div 
                  :style="{
                    color: handleVerifyIsSignColor(
                      scope.row.isReimburse
                    ),
                  }"
                  >
                  <span>{{
                    scope.row.isReimburse == 1 ? "是" : "否" || "--"
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="columns['created'].visible" prop="created" :label="columns['created'].label" width="160" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['creatorName'].visible" prop="creatorName" :label="columns['creatorName'].label" width="120" />
            <el-table-column v-if="columns['sumAmount'].visible" prop="sumAmount" :label="columns['sumAmount'].label" width="100" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['invoiceCount'].visible" prop="invoiceCount" :label="columns['invoiceCount'].label" />
            <el-table-column v-if="columns['remark'].visible" prop="remark" :label="columns['remark'].label" />
            <el-table-column v-if="columns['reimburseUrl'].visible" prop="reimburseUrl" :label="columns['reimburseUrl'].label" width="200" :show-overflow-tooltip="true" />
            <el-table-column v-if="columns['voucherNo'].visible" prop="voucherNo" :label="columns['voucherNo'].label" width="150" :formatter="handleTableValueFormat" />
            <el-table-column v-if="columns['operate'].visible" prop="operate" :label="columns['operate'].label" width="140" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="handleViewDetails(scope.row)"> 详情 </el-button>
                <el-button type="text"
                    v-clipboard:copy="scope.row.reimburseUrl"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                >复制链接</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[15, 30, 50, 100]" :page-size="search.size" layout="total, sizes, prev, pager, next" :total="total"> </el-pagination>
      </div>
    </div>

    <!-- 票据详情弹窗 -->
    <reimburse-invoice-detail :lockObject="lockObject" :show.sync="reimburseVisible" @handleDetailCancel="handleDetailCancel"></reimburse-invoice-detail>
     
  </div>
</template>

<script>
import { searchReimburseInvoicesPage } from '@/service/invoice-reimburse';
import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins';
import ReimburseInvoiceDetail from '@/components/invoice/ReimburseInvoiceDetail';
import { MANUAL_INVOICE_TYPES } from '@/content/invoice';

export default {
  name: 'OtherInvoice',
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  components: { ReimburseInvoiceDetail },
  data() {
    return {
      exportQuantity: 0,
      exportConfirmation: false,
      invoiceId: '',
      columns: {
        batchNo: {
          label: '报销码',
          visible: true
        },
        isReimburse: {
          label: '是否报销',
          visible: true
        },
        created: {
          label: "提报时间",
          visible: true,
        },
        creatorName: {
          label: '提报人',
          visible: true
        },
        sumAmount: {
          label: '价税合计',
          visible: true
        },
        invoiceCount: {
          label: '发票数量',
          visible: true
        },
        remark: {
          label: '报销描述',
          visible: true
        },
        reimburseUrl: {
          label: '报销链接',
          visible: true
        },
        voucherNo: {
          label: '凭证编号',
          visible: true
        },
        operate: {
          label: '操作',
          visible: true
        }
      },
      isIndeterminate: true,
      checkAll: false,
      deductionStatusArr: [],
      buyerInfoAuditArr: [],
      auditStatusArr: [],
      isCollect: true,
      search: {
        creatorName: '',
        batchNo: '',
        isReimburse: undefined,
        receiptDate: undefined,
        archivingStatus: '',
        page: 1,
        size: 15,
      },
      total: 0,
      tableData: [],
      invoiceStatus: [],
      manualVisible: false,
      phoneVisible: false,
      tableLoading: false,
      archivingStatusAllSet: [],
      manualInvoiceTypes: MANUAL_INVOICE_TYPES,
      sealStatus: [],
      qrCodeImg: '',
      /* 票据详情数据区 */
      drawer: false,
      invoice: {},
      /* 票据影像数据区 */
      invoiceImageViewer: false,
      invoiceImages: [],
      /* 详情 */
      reimburseVisible: false,
      lockObject: {
        id: '',
        invoiceType: '',
        invoiceCode: '',
        invoiceNo: '',
        amount: '',
        checkCode: '',
        verifyCode: '',
        invoiceTime: '',
        buyerName: '',
        buyerTaxNo: '',
        invoiceLabel: 'ordinary',
        rider: '',
        train21No: '',
        action: ''
      },
      /* 编辑发票说明 */
      editVisibleExplain: false,
      invoiceExplain: '',
      invoiceExplainUpdateIds: [],
      /* 批量变更结算状态区 */
      isBatch: false,
      /* 票据删除数据区 */
      auditBatchIds: [],
      auditWindow: false,
      selections: [],
      selectExpended: false,
      downLoading: false,
      exportLoading: false,
      invoiceListVisible: false,
      innerVisible: false,
      invoiceListData: [],
      invoiceListPageSize: 0,
      invoiceListUnmatched: [],
      invoiceListUpdateData: {}
    };
  },
  watch: {
    '$route.path': {
      handler() {
        if (this.$route.path == '/collect/list') {
          this.isCollect = true;
        } else {
          this.isCollect = false;
        }
        this.handleQuery();
      },
      immediate: true
    }
  },
  created() {
    this.handleInitDictionaries();
    this.archivingStatusAllSet = [{label: '未归档', value: '0'},{label: '已归档', value: '1'}];
    this.sealStatus = [{ label: '全部', value: '' }, ...this.$store.state.enums.InvoiceSealStatus];
  },
  methods: {
    handleDetailCancel() {
      this.reimburseVisible = false;
    }, 
    // 表格合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '共:';
          return;
        }
        if (index === 1) {
          sums[index] = this.selections.length + '张';
          return;
        }
        const values = this.selections.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value)) && (column.label === '票价' || column.label === '税额' || column.label === '价税合计')) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return Number((prev + curr).toFixed(2));
            } else {
              return prev;
            }
          }, 0);
          if (column.label === '票价') {
            sums[index] = '票价：' + sums[index];
          } else if (column.label === '税额') {
            sums[index] = '税额：' + sums[index];
          } else if (column.label === '价税合计') {
            sums[index] = '价税合计：' + sums[index];
          }
        } else {
          if (column.label === '票价') {
            sums[index] = '票价：' + 0;
          } else if (column.label === '税额') {
            sums[index] = '税额：' + 0;
          } else if (column.label === '价税合计') {
            sums[index] = '价税合计：' + 0;
          }
        }
      });
      return sums;
    },
    // 复制地址回调
    onCopy: function () {
      this.$message.success('复制成功')
    },
    onError: function () {
      this.$message.error('复制失败')
    }, 
    //列设置全选
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      let cols = this.columns;
      for (let p in cols) {
        cols[p].visible = val;
      }
      localStorage.setItem('otherInvoiceLineSet', JSON.stringify(this.columns));
    },
    //列设置改变
    handleCheckedCitiesChange(pk, pv) {
      Object.keys(this.columns).some((key) => {
        if (pk == key) {
          this.columns[key].visible = pv.visible;
          return true;
        }
      });
      localStorage.setItem('otherInvoiceLineSet', JSON.stringify(this.columns));
    },
    /* 收票中心数据列表 */
    async handleGetInvoiceData() {
      this.tableLoading = true;
      this.search.classify = 'GR';
      const { success, data } = await searchReimburseInvoicesPage(this.search);
      if (!success) {
        this.toast('报销发票单数据获取失败！', 'error');
        this.tableLoading = false;
        return;
      }
      // 查询结果处理
      this.total = data.total;
      this.tableData = data.records.map((item) => {
        this.$set(item, 'loading', false);
        return item;
      });
      this.tableLoading = false;
    },
    /* 表格值格式化 */
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '税额':
        case '票价':
        case '价税合计':
          if (value == null) return '--';
          return '￥' + value;
        case '乘车人':
        case '识别批次':
        case '上传者':
          if (value) return value;
          return '--';
        case '开票日期':
          if (value != undefined) {
            return this.$moment(value).format('YYYY-MM-DD');
          }
          return '--';
        default:
          return value;
      }
    },
    /* 初始化字典值 */
    handleInitDictionaries() {
      // 发票状态
      this.getDicts('invoice_status').then(
        ({ data }) =>
          (this.invoiceStatus = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );
 
      // 稽核状态
      this.getDicts('audit_status').then(
        ({ data }) =>
          (this.auditStatusArr = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );

      // 抵扣状态
      this.getDicts('deduction_status').then(
        ({ data }) =>
          (this.deductionStatusArr = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );
      // 购方信息稽核
      this.getDicts('buyer_info_audit').then(
        ({ data }) =>
          (this.buyerInfoAuditArr = [
            {
              label: '全部',
              value: ''
            },
            ...data
          ])
      );
    },
    /* 展开筛选列表 */
    handleListShow() {
      this.selectExpended = true;
    },
    /* 收起筛选列表 */
    handleListClose() {
      this.selectExpended = false;
    },
    /* 重置查询 */
    handleReset() {
      this.search = {
        creatorName: '',
        batchNo: '',
        receiptDate: undefined,
        archivingStatus: '',
        page: 1,
        size: 15,
      };
    },
    /* 条件查询 */
    handleQuery() {
      this.handleGetInvoiceData();
    },

    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value;
    },
    /* 发票详情 */
    handleViewDetails(invoice) {
      this.reimburseVisible = true;
      this.lockObject = {
        id: invoice.id,
      };
    }, 
    /* 切换分页条数 */
    handleSizeChange(value) {
      this.search.size = value;
      this.handleQuery();
    },
    // 翻页
    handleCurrentChange(value) {
      this.search.page = value;
      this.handleQuery();
    },
  }
};
</script>
<style lang="scss" scoped>
@import '@/style/select.scss';

.select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      color: #3d94ff;
      margin-right: 20px;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;

      img {
        width: 20px;
        height: 20px;
        vertical-align: sub;
        margin-right: 4px;
      }
    }

    div:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .select-title span:before {
    content: '|';
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .el-button {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1890ff;
        line-height: 22px;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 2px;
          margin-left: 20px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }

  .content-table {
    .el-button {
      padding: 0;
      margin-right: 10px;
    }
  }
}

.manual-input {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;

    ::v-deep .el-select > .el-input,
    .el-input {
      width: 250px;
    }
  }
}

.phone-scan {
  .phone-scan-text {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-bottom: 12px;
    }
  }

  .phone-scan-img {
    .el-image {
      width: 260px;
      height: 260px;
    }
  }
}

.invoice-update {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}

.el-image-viewer {
  position: absolute;
  z-index: 1000;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  margin-bottom: 24px;

  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }

  .message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}

.dialog-delete {
  display: flex;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-invoice-list {
  .list-statistics {
    text-align: left;

    .el-row {
      margin-bottom: 12px;
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .list-table {
    margin-top: 12px;

    div {
      text-align: left;
      margin-bottom: 12px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .el-button {
      padding: 0;
    }
  }
}

.dialog-settlement {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  text-align: left;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0px 24px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-dialog__footer {
  padding: 0px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

.invoice-deleted {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 200px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.invoice-deleted-hidden {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 64px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.dialog-wrap {
  span {
    color: #333333;
  }

  .deletes-btns {
    padding-top: 24px;
    text-align: right;
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.icon {
  color: #ff0000;
  margin-left: 3px;
}

.button-footer {
  text-align: center;

  .button-left {
    margin-right: 65px;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.train21No {
  margin-right: 31px;
  margin-left: -31px;
  .el-input {
    width: 150px;
  }
}
</style>
<style lang="scss">
.el-form--inline .el-form-item {
  margin: 10px 10px 10px 0;
}
.select-button {
  min-width: 200px;
  margin-left: 30px;
}
</style>
