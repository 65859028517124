<template>
  <el-dialog append-to-body
             :visible.sync="show"
             :close-on-click-modal="true"
             :close-on-press-escape="true"
             title="勾选导出字段"
             @close="$emit('set-column-settings', invoiceField, itemField)">
    <div style="text-align: left;padding-left: 20px">
      <el-checkbox @change="handleInvoiceCheckboxChange" v-model="invoiceFieldSign" style="color: red">导出所有发票信息</el-checkbox>
      <div style="height: 10px"></div>
      <el-checkbox style="width: 140px;text-align: left" v-for="(pv,pk) in invoiceField" :label="pv.label" :key="pk" v-model="pv.visible" @change="handleCheckedInvoiceChange(pk,pv)"/>
    </div>

    <div style="height: 30px;"></div>
    <div style="text-align: left;padding-left: 20px">
      <el-checkbox @change="handleItemCheckboxChange" v-model="itemFieldSign" style="color: red">导出所有发票明细信息</el-checkbox>
      <div style="height: 10px"></div>
      <el-checkbox style="width: 140px" v-for="(pv, pk) in itemField" :label="pv.label" :key="pv.label" v-model="pv.visible" @change="handleCheckedItemChange(pk,pv)"/>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'InvoiceExportSetting',
  props: ['show'],
  data () {
    return {
      invoiceField: {
        invoiceType: {
          label: '发票类型',
          visible: true
        },
        invoiceCode: {
          label: '发票代码',
          visible: true
        },
        invoiceNo: {
          label: '发票号码',
          visible: true
        },
        eInvoiceNo: {
          label: '数电发票号码',
          visible: true
        },
        remark: {
          label: '备注',
          visible: true
        },
        sumAmount: {
          label: '价税合计',
          visible: true
        },
        amount: {
          label: '金额',
          visible: true
        },
        taxAmount: {
          label: '税额',
          visible: true
        },
        buyerInfoAudit: {
          label: '购方信息稽核',
          visible: true
        },
        sellerName: {
          label: '销方名称',
          visible: true
        },
        invoiceTime: {
          label: '开票日期',
          visible: true
        },
        orgName: {
          label: '上传组织',
          visible: true
        },
        receiveTime: {
          label: '收票时间',
          visible: true
        },
        verifyStatus: {
          label: '查验状态',
          visible: true
        },
        invoiceStatus: {
          label: '发票状态',
          visible: true
        },
        sealStatus: {
          label: '验章状态',
          visible: true
        },
        settlementStatus: {
          label: '结算状态',
          visible: true
        },
        auditStatus: {
          label: '稽核状态',
          visible: true
        },
        deductionStatus: {
          label: '抵扣状态',
          visible: true
        },
        invoiceExplain: {
          label: '发票说明',
          visible: true
        },
        batchNo: {
          label: '识别批次',
          visible: true
        },
        imageType: {
          label: '影像类型',
          visible: true
        },
        creatorName: {
          label: '上传者',
          visible: true
        },
        buyerName: {
          label: '购方名称',
          visible: true
        }
      },
      itemField: {
        unit: {
          label: '单位',
          visible: false
        },
        quantity: {
          label: '数量',
          visible: false
        },
        unTaxPrice: {
          label: '单价',
          visible: false
        },
        taxRate: {
          label: '税率',
          visible: false
        },
        itemAmount: {
          label: '明细金额',
          visible: false
        },
        itemTaxAmount: {
          label: '明细税额',
          visible: false
        },
        buyerTaxNo: {
          label: '购方税号',
          visible: false
        },
        buyerBankAccount: {
          label: '购方开户行及账号',
          visible: false
        },
        buyerAddressTel: {
          label: '购方地址电话',
          visible: false
        },
        merchandiseName: {
          label: '货物或应税劳务名称',
          visible: false
        },
        sellerTaxNo: {
          label: '销方税号',
          visible: false
        },
        sellerBankAccount: {
          label: '销方开户行及账号',
          visible: false
        },
        sellerAddressTel: {
          label: '销方地址、电话',
          visible: false
        },
        specification: {
          label: '规格型号',
          visible: false
        }
      },
      invoiceFieldSign: true,
      itemFieldSign: false
    };
  },
  computed: {},
  created() {

  },
  methods: {
    handleCheckedInvoiceChange(pk, pv) {
      Object.keys(this.invoiceField).some((key) => {
        if (pk == key) {
          this.invoiceField[key].visible = pv.visible
          return true
        }
      })
    },
    handleCheckedItemChange(pk, pv) {
      Object.keys(this.itemField).some((key) => {
        if (pk == key) {
          this.itemField[key].visible = pv.visible
          return true
        }
      })
    },
    handleInvoiceCheckboxChange() {
      if (this.invoiceFieldSign) {
        Object.keys(this.invoiceField).forEach(key => this.invoiceField[key].visible = true)
      } else {
        Object.keys(this.invoiceField).forEach(key => this.invoiceField[key].visible = false)
      }
    },
    handleItemCheckboxChange() {
      if (this.itemFieldSign) {
        Object.keys(this.itemField).forEach(key => this.itemField[key].visible = true)
      } else {
        Object.keys(this.itemField).forEach(key => this.itemField[key].visible = false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog__body {
}

</style>
