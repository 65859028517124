<template>
    <!-- 数电发票(增值税专用发票) || 数电发票(普通发票) || ... -->
    <div>
        <el-form :model="invoiceData" label-width="120px">
          <el-form-item label="发票类型">
            <el-input disabled v-model="invoiceData.invoiceTypeName"></el-input>
          </el-form-item>
            <el-form-item label="数电票号">
                <el-input v-model="invoiceData.eInvoiceNo"></el-input>
            </el-form-item>
            <el-form-item label="电子客票号码">
                <el-input v-model="invoiceData.train21No"></el-input>
            </el-form-item>
            <el-form-item label="开票日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="invoiceData.invoiceTime" type="date"  :clearable="false"></el-date-picker>
            </el-form-item>
            <el-form-item label="校验码">
                <el-input v-model="invoiceData.checkCode"></el-input>
            </el-form-item>
            <el-form-item label="价税合计">
                <el-input-number v-model="invoiceData.sumAmount" :precision="2" :controls="false" style="width:200px;"></el-input-number>
            </el-form-item>
            <el-form-item label="税额">
                <el-input-number v-model="invoiceData.taxAmount" :precision="2" :controls="false" style="width:200px"></el-input-number>
            </el-form-item>
            <el-form-item label="发票金额">
                <el-input-number v-model="invoiceData.amount" :precision="2" :controls="false" style="width:200px"></el-input-number>
            </el-form-item>
            <el-form-item label="购买方名称">
                <el-input v-model="invoiceData.buyerName"></el-input>
            </el-form-item>
            <el-form-item label="购买方税号">
                <el-input v-model="invoiceData.buyerTaxNo"></el-input>
            </el-form-item>
            <el-form-item label="购买方地址电话">
                <el-input v-model="invoiceData.buyerAddressTel"></el-input>
            </el-form-item>
            <el-form-item label="购买方银行账号">
                <el-input v-model="invoiceData.buyerBankAccount"></el-input>
            </el-form-item>
            <el-form-item label="销售方名称">
                <el-input v-model="invoiceData.sellerName"></el-input>
            </el-form-item>
            <el-form-item label="销售方税号">
                <el-input v-model="invoiceData.sellerTaxNo"></el-input>
            </el-form-item>
            <el-form-item label="销售方地址电话">
                <el-input v-model="invoiceData.sellerAddressTel"></el-input>
            </el-form-item>
            <el-form-item label="销售方银行账号">
                <el-input v-model="invoiceData.sellerBankAccount"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="invoiceData.remark"></el-input>
            </el-form-item>
            <el-form-item label="发票说明">
                <el-input v-model="invoiceData.invoiceExplain"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    name: "VATInvoiceForm",
    props: ["invoiceData"]
}
</script>
<style scoped>
</style>
